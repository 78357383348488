import {
    Image,
    Caption,
    LabelParagraph,
  } from '@maggioli-design-system/react'
  import clsx from 'clsx'
  import questionVariant from './variant.json'
  
  type AnswerStatusType =
    | 'completed'
    | 'correct'
    | 'default'
    | 'not-user-answered'
    | 'wrong'
  
  interface IAnswerBadge {
    className?: string,
    status?: AnswerStatusType,
  }
  
  const AnswerBadge: React.FC <IAnswerBadge> = ({
    status,
    className,
  }: IAnswerBadge): JSX.Element =>
    <LabelParagraph className={clsx(className, questionVariant[status].badge, 'absolute flex leading-none px-4 py-2 rounded-full')}>
      { status === 'wrong' && 'Sbagliata' }
      { status === 'correct' && 'Corretta' }
    </LabelParagraph>
  
  interface IAnswer {
    completed?: boolean,
    children?: React.ReactNode,
    letter?: string,
    src?: string,
    status?: AnswerStatusType,
  }
  
  const Question: React.FC <IAnswer> = ({
    children,
    completed,
    src,
    letter,
    status = 'default',
  }: IAnswer): JSX.Element => {

    const questionText: string = children === null ? '' : children.toString();

    return <div className={clsx(
      questionVariant[status].background,
      completed && questionVariant[status].border,
      'flex flex-col gap-0 items-start min-h-10 relative rounded-2xl'
      )}>
        { src &&
          <div className="p-2 w-full">
            <Image className="w-full rounded-2xl" src={ src }/>
          </div>
        }
        <div className="flex items-start gap-4 px-2 py-2 flex-grow w-full">
          <Caption className={clsx(questionVariant[status].letter, 'flex flex-shrink-0 items-center justify-center rounded-full uppercase w-8')}>
            <b>{letter}</b>
          </Caption>
          <div className="flex-grow gap-2 grid">
            <Caption><b dangerouslySetInnerHTML={{ __html: questionText }}></b></Caption>
            { completed && status !== "not-user-answered" && <AnswerBadge className={clsx(src ? 'right-4 top-4' : 'right-2 top-2')} status={status}/> }
          </div>
        </div>
    </div>

  }
  
  export default Question
  