import {
  Banner,
  Detail,
  H2,
  Grid,
} from '@maggioli-design-system/react'
import { useEffect } from 'react'

import QuizCard from '../../components/QuizCard'
import { IFavManager } from '../../interfaces/IFavManager'

interface Props {
  favManager: IFavManager
}

const FavouriteQuizzes: React.FC <Props> = ({ favManager }) : JSX.Element => {

  useEffect(() => {
    favManager.setFav(JSON.parse(localStorage.getItem('favourites')));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const favList = () => {
    if (favManager.fav === null || favManager.fav.length === 0) {
      return <Banner status="warning" className="border-radius-small">
              <div className="w-96 pb-5"><Detail>Non sono presenti conrcorsi preferiti.</Detail></div>
            </Banner>
    }
    return favManager.fav.map((e, i) => <div key={i} className="pb-5">
      <QuizCard data={e} favManager={favManager} isFavCard={true}/>
    </div>);
  }

  return <div className="bg-adjust-tone-18 py-12 mobile:pt-4">
    <H2 className="px-24 pb-3">Preferiti</H2>
    <Grid columns="4" className="px-20 mobile:px-4 ">
      { favList() }
    </Grid>
  </div>
}

export default FavouriteQuizzes;
