import {
  BenchmarkBar,
  Button,
  Grid,
  H5,
  Icon,
  Modal,
} from '@maggioli-design-system/react'
import DescriptionTip from '../DescriptionTip'
import { difficulty } from '../../functions/difficulty'
import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { fromMsToTime, scorePerTheme, timeConvert } from '../../utils/Functions'
import { ISimulation } from '../../interfaces/ISimulation'

interface IQuizTopBar {
  className?: string,
  numQuestion: number,
  questionTime: number,
  time: number,
  diff: number,
  numGivenAns: number,
  simulation: ISimulation,
}

const QuizTopBar: React.FC <IQuizTopBar> = ({
  className,
  numQuestion,
  questionTime,
  diff,
  time,
  numGivenAns,
  simulation,
}: IQuizTopBar) : JSX.Element => {
  const [ visible, setVisible ] = useState(false);

  const totQuestions: number = parseInt(simulation.settings.data.total_questions);
  const totPoints: number = scorePerTheme(simulation.settings.data) ? parseFloat(simulation.settings.data.themes.reduce((a, b) => a + (b.number * b.right), 0))
                            : parseFloat(simulation.settings.data.right) * totQuestions;
  const progress: number = Math.floor((numGivenAns / totQuestions) * 100);
  const minPoints: number = parseFloat(simulation.settings.data.min_score);
  const totalTime: number = parseInt(simulation.settings.data.time);

  return <Fragment>
    <div className={clsx(
      className,
      'flex items-center justify-between flex-wrap',
      )}>
      <div className="flex flex-wrap gap-6 items-center flex-grow">
        <div className="flex items-stretch text-primary-h4 rounded-full overflow-hidden text-status-info-04 flex-shrink-0">
          <div className="bg-status-info-19 pr-2 pl-4">{numQuestion}</div>
          <div className="bg-status-info-17 pl-2 pr-4">{totQuestions}</div>
        </div>
        <Button className="hidden tablet-max:flex ml-auto" variant="secondary-outline" onClick={() => setVisible(!visible)}>Statistiche</Button>

        <BenchmarkBar className="flex-basis-0 tablet-max:hidden gap-1 pb-1 w-44" autoColor={diff > 0} colorInvert progress={diff} 
            progressText={difficulty(diff)}>Difficoltà {diff === 0 ? '' : ''}</BenchmarkBar>

        <BenchmarkBar className="flex-basis-0 tablet-max:hidden gap-1 pb-1 w-44" progress={progress}>Progressione</BenchmarkBar>

        <div className="gap-0 flex-basis-0 tablet-max:hidden w-44">
          <DescriptionTip value={`${minPoints}pt`}>Punteggio minimo</DescriptionTip>
          <DescriptionTip value={`${totPoints.toString()}pt`}>Punti totali</DescriptionTip>
        </div>
      </div>

      <div className="flex flex-wrap gap-6 items-center flex-grow tablet-max:hidden large:justify-end"> 
        <div className="p-1 bg-status-info-19 flex rounded-md flex-shrink-0 tablet-max:hidden">
          <Icon className="text-status-info-04" name="timer-outline"/>
        </div>
        <div className="gap-6 flex-shrink-0">
          <DescriptionTip value=' '>Tempo domanda</DescriptionTip>
          <DescriptionTip value=' '>{fromMsToTime(questionTime)}</DescriptionTip>
        </div>
        <div className="gap-0 flex-shrink-0">
          <DescriptionTip value={fromMsToTime(time)}>Trascorso</DescriptionTip>
          <DescriptionTip value={timeConvert(totalTime)}>Totale</DescriptionTip>
        </div>
      </div>
    </div>

    <Modal footer={false} visible={visible} onCancel={() => setVisible(false)}>
      <Grid className="gap-6">
        <div className="flex flex-wrap gap-6 items-center flex-grow">
          <H5>Domanda</H5>
          <div className="flex items-stretch text-primary-h4 rounded-full overflow-hidden text-status-info-04 flex-shrink-0">
            <div className="bg-status-info-19 pr-2 pl-4">{numQuestion}</div>
            <div className="bg-status-info-17 pl-2 pr-4">{totQuestions}</div>
          </div>
          <BenchmarkBar className="flex-grow mobile:w-full gap-0 pb-1" autoColor colorInvert progress={diff} progressText={difficulty(diff)}>Difficoltà</BenchmarkBar>
          <BenchmarkBar className="flex-grow mobile:w-full gap-0 pb-1" progress={progress}>Progressione</BenchmarkBar>
          <div className="gap-0 flex-shrink-0">
            <DescriptionTip lineHeight="leading-4" value="14 pt">Parziali</DescriptionTip>
            <DescriptionTip lineHeight="leading-4" value="600 pt">Totali</DescriptionTip>
          </div>
          <div className="p-1 bg-status-info-19 flex rounded-md flex-shrink-0 mobile:hidden">
            <Icon className="text-status-info-04" name="timer-outline"/>
          </div>
          <DescriptionTip lineHeight="leading-4" value={fromMsToTime(questionTime)}>Tempo domanda</DescriptionTip>
          <div className="gap-0 flex-shrink-0">
            <DescriptionTip lineHeight="leading-4" value={fromMsToTime(totalTime)}>Trascorso</DescriptionTip>
            <DescriptionTip lineHeight="leading-4" value="1 ora e 30 minuti">Rimanente</DescriptionTip>
          </div>
        </div>
      </Grid>
    </Modal>
  </Fragment>
}
export default QuizTopBar
