import { Grid } from "@maggioli-design-system/react";
import { IAnswer } from "../../interfaces/IAnswer";
import { IGivenAns } from "../../interfaces/IGivenAnswer";
import { IQuestion } from "../../interfaces/IQuestion";

import { ALPHABET, IMAGE_EXPRESS_URL } from "../../utils/Macros";
import Answer from "../Answer/Answer";

interface IQuizAnswers {
    currentQuestion: IQuestion;
    givenAns: IGivenAns[];
    selectAnswer: Function;
    showRight: boolean;
    recap?: boolean;
}

const QuizAnswers: React.FC<IQuizAnswers> = ({ currentQuestion, givenAns, selectAnswer, showRight, recap = false }: IQuizAnswers): JSX.Element => {
    const regex = /<img.*?src="(.*?)"/;

    if (!currentQuestion.answers) {
        return <div></div>;
    }

    const isOldImage = (title: string) => typeof title === "string" && title.includes("/quiz/images/");

    const imageSrc = (e: IAnswer) => {
        if (isOldImage(e.title)) {
            const path: string = regex.exec(e.title)[1].replace("quiz", "uploads");
            return `${IMAGE_EXPRESS_URL}url=${path}`;
        }
        return e.metadata.data !== undefined && e.metadata.data.url !== undefined ? `${IMAGE_EXPRESS_URL}url=${e.metadata.data.url}` : "";
    };

    const answerText = (e: IAnswer) => {
        if (isOldImage(e.title)) {
            return e.title.substring(0, e.title.indexOf("<br/>"));
        }
        return e.title;
    };

    const maxBoxHeight = () => {
        let height: number = 0;
        currentQuestion.answers.forEach((q) => {
            height = q.title.length > height ? q.title.length : height;
        });
        return height < 130
            ? "tablet:h-20"
            : height < 255
            ? "tablet:h-32"
            : height < 380
            ? "tablet:h-44"
            : height > 500
            ? "tablet:h-72"
            : "tablet:h-56";
    };

    if (recap) {
        return (
            <Grid className="pb-8 gap-6 grid grid-cols-2 mobile:grid-cols-1">
                {currentQuestion.answers.map((e, i) => {
                    const answerImage: string = imageSrc(e);
                    const isSelected: boolean = givenAns.map((g) => g.answerID).includes(e.id);
                    const tempStatus = e.result === "1" ? "correct" : isSelected && e.result === "0" ? "wrong" : "not-user-answered";

                    return (
                        <div className="cursor-pointer" key={i} onClick={() => selectAnswer(e)} id={`answer-${ALPHABET[i]}`}>
                            <Answer answered={isSelected} status={tempStatus} letter={ALPHABET[i]} src={answerImage} boxHeight={maxBoxHeight()}>
                                {answerText(e)}
                            </Answer>
                        </div>
                    );
                })}
            </Grid>
        );
    }

    return (
        <Grid className="pb-8 gap-6 grid grid-cols-2 mobile:grid-cols-1">
            {currentQuestion.answers.map((e, i) => {
                const answerImage: string = imageSrc(e);
                const isAnswered: boolean = givenAns
                    .filter((g) => g.answerID !== null)
                    .map((g) => g.questionID)
                    .includes(currentQuestion.id);
                if (!isAnswered) {
                    return (
                        <div className="cursor-pointer" key={i} onClick={() => selectAnswer(e)} id={`answer-${ALPHABET[i]}`}>
                            <Answer answered={false} letter={ALPHABET[i]} src={answerImage} boxHeight={maxBoxHeight()}>
                                {answerText(e)}
                            </Answer>
                        </div>
                    );
                }

                const isSelected: boolean = givenAns.map((g) => g.answerID).includes(e.id);
                const tempStatus = e.result === "1" ? "correct" : isSelected && e.result === "0" ? "wrong" : "not-user-answered";
                const status = showRight ? tempStatus : isSelected ? "answered" : "not-user-answered";

                return (
                    <div className="cursor-pointer" key={i} onClick={() => selectAnswer(e)} id={`answer-${ALPHABET[i]}`}>
                        <Answer answered={isSelected} status={status} letter={ALPHABET[i]} src={answerImage} boxHeight={maxBoxHeight()}>
                            {answerText(e)}
                        </Answer>
                    </div>
                );
            })}
        </Grid>
    );
};

export default QuizAnswers;
