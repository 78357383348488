import { useEffect, useState } from "react";
import { Caption, Row } from "@maggioli-design-system/react";
import { Routes, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { ILoader } from "./interfaces/ILoader";
import Home from "./pages/Home";
import HomeLogged from "./pages/HomeLogged";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/NotFound/NotFound";
import QuizPage from "./pages/QuizPage";
import Cookies from "./pages/Cookies/Cookies";
import Contact from "./pages/Contact/Contact";
import QuizPlay from "./pages/QuizPlay/QuizPlay";
import QuizResults from "./pages/QuizResults/QuizResults";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import Activate from "./pages/Activate/Activate";
import QuestionPreview from "./pages/QuestionPreview.tsx/QuestionPreview";
import Help from "./pages/Help/Help";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import ResetPassword from "./pages/ResetPassword /ResetPassword";
import { getCookie } from "./utils/Functions";
import { APPRO_URL } from "./utils/Macros";

const App: React.FC = (): JSX.Element => {
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [isWorker, setIsWorker] = useState<boolean>(false);
    const IS_LOGGED: boolean = getCookie("loggedUser") !== null;
    const currentYear: number = new Date().getFullYear();

    const handleLoader: ILoader = {
        loaderVisible: loaderVisible,
        setLoaderVisible: (v) => setLoaderVisible(v),
    };

    useEffect(() => {
        checkUserAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkUserAccess = async () => {
        if (!IS_LOGGED) {
            return;
        }
        setLoaderVisible(true);
        const loggedUser = JSON.parse(getCookie("loggedUser"));
        const res = await fetch(`${APPRO_URL}core/auth/api/v2/get/users/canAccess/${loggedUser.id}/${loggedUser.email}`, {
            method: "GET",
            headers: {
                "Authorization": "Basic " + btoa(`${process.env.REACT_APP_APPRO_USER}:${process.env.REACT_APP_APPRO_PASS}`),
                "Content-Type": "application/json",
            },
        });

        setLoaderVisible(false);
        if (!res.ok) {
            return false;
        }
        const data = await res.json();
        setIsWorker(data.status);
    };

    const homeLogged = <HomeLogged handleLoader={handleLoader} />;
    const home = <Home handleLoader={handleLoader} />;

    return (
        <div>
            <Header isWorker={isWorker} />
            <Routes>
                <Route path="/" element={IS_LOGGED ? homeLogged : home}></Route>
                <Route path="/guida" element={<Help handleLoader={handleLoader} />}></Route>
                <Route path="/free" element={homeLogged}></Route>
                <Route path="/register" element={IS_LOGGED ? homeLogged : <RegisterPage handleLoader={handleLoader} />}></Route>
                <Route path="/login" element={IS_LOGGED ? homeLogged : <LoginPage />}></Route>
                <Route path="/home/:page" element={IS_LOGGED ? homeLogged : home}></Route>
                <Route path="/user-home/:page" element={IS_LOGGED ? homeLogged : <NotFound />}></Route>
                <Route path="/quiz/:id" element={<QuizPage handleLoader={handleLoader} isWorker={isWorker} />}></Route>
                <Route path="/quiz/:simulationID/:flatID/play" element={<QuizPlay handleLoader={handleLoader} />}></Route>
                <Route path="/question/preview/:id" element={<QuestionPreview handleLoader={handleLoader} />}></Route>
                <Route path="/quiz/:id/results" element={<QuizResults handleLoader={handleLoader} />}></Route>
                <Route path="/informativa-sui-cookies" element={<Cookies handleLoader={handleLoader} />}></Route>
                <Route path="/contact" element={<Contact handleLoader={handleLoader} />}></Route>
                <Route path="/:passCode" element={IS_LOGGED ? homeLogged : home}></Route>
                <Route path="/quiz/:id/:passCode" element={<QuizPage handleLoader={handleLoader} isWorker={isWorker} />}></Route>
                <Route path="/recover" element={<RecoverPassword handleLoader={handleLoader} />}></Route>
                <Route path="/resetPassword/:code" element={<ResetPassword handleLoader={handleLoader} />}></Route>
                <Route path="/activate/:passCode" element={<Activate />}></Route>
                <Route path="/register/free" element={IS_LOGGED ? homeLogged : <RegisterPage handleLoader={handleLoader} />}></Route>
            </Routes>
            <Footer loaderVisible={loaderVisible} />

            <div style={{ color: "white" }} className="bg-brand-maggioli-04">
                <Row className="view-limit p-4">
                    <Caption className="m-auto">
                        Copyright 2003-{currentYear} Maggioli Spa - P.Iva 02066400405 - Iscritta al R.E.A. di Rimini al n. 219107
                    </Caption>
                </Row>
            </div>
        </div>
    );
};

export default App;
