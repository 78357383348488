import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
    Button,
    Banner,
    Detail,
    Form,
    Grid,
    H3,
    InputText,
    InputPassword,
    Radio,
    BackofficeSelectOption,
    Select,
} from "@maggioli-design-system/react";
import { ILoader } from "../../interfaces/ILoader";
import { passwordValidation } from "../../utils/Validators";
import { emailValidation } from "../../utils/Functions";
import { getForms, sendDataToSubscriptions } from "../../services/PrivacyService";
import { ambiti, BASE_URL, emailParams, EXPRESS_URL } from "../../utils/Macros";
import { newUser } from "../../services/ApproService";
import Connection from "../../utils/Connection";

interface ISubmit {
    text: string;
    status: string;
}

interface Props {
    handleLoader: ILoader;
    free: boolean;
}

const TOKEN: string =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwcml2YWN5Lm1hZ2dpb2xpY2xvdWQuaXQiLCJhdWQiOjEsImlhdCI6MTYzMjk5ODY3OCwibmJmIjoxNjMyOTk4Njc4LCJleHAiOjE5NDgzNTg2NzgsImRhdGEiOnsiaWQiOjc1LCJzaXRlX2lkIjoxMDN9fQ.fzjC_iJsoUdHD7dgKrE5CA_H5nhoA6DSi6a7ycBjq94";
const INIT_TEXT: string = "Invia";
const INIT_STAT: string = "";

export default function Registerform({ handleLoader, free = false }: Props): ReactElement {
    const [form, setForm] = useState<any>(null);
    const [commerciale, setCommerciale] = useState<string>(null);
    const [ambito, setAmbito] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });

    useEffect(() => {
        handleLoader.setLoaderVisible(true);
        getForms(TOKEN, (result: any) => {
            handleLoader.setLoaderVisible(false);
            if (result.form.length > 0) {
                const formType: string = free ? "registrazione-prova" : "registrazione";
                setForm(result.form.filter((elem) => elem.nome === formType).pop());
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value);
                break;
            default:
                break;
        }
    };

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const resIP = await Connection.get(`${EXPRESS_URL}ip`);
        if (!resIP.ok) {
            alert("Errore nel recupero dell'IP");
            return;
        }
        const dataIP = await resIP.json();
        const formData = new FormData(e.target);
        const data = {};
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }
        data["token"] = TOKEN;
        data["ip"] = dataIP.ip;

        if (commerciale === null || data["email"] === "" || data["nome"] === "" || data["cognome"] === "") {
            setSubmit({ text: "Tutti i campi sono obbligatori.", status: "error" });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 2000);
            return;
        }

        if (!passwordValidation(data["password"], data["password2"])) {
            setSubmit({ text: "La password è obbligatoria e deve essere lunga almeno 6 caratteri. Le password devono coincidere.", status: "error" });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 2000);
            return;
        }
        if (!emailValidation(data["email"])) {
            setSubmit({ text: "Email non valida.", status: "error" });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 2000);
            return;
        }
        if (free && ambito === "") {
            setSubmit({ text: "L'ambito è obbligatorio.", status: "error" });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 2000);
            return;
        }

        const res = await newUser(data["nome"], data["cognome"], data["email"], data["password"], data["password2"]);
        if (!res.status) {
            setSubmit({
                text: "L'email è già presente in Approfondimenti. Puoi utilizzare le relative credenziali per accedere ai Quiz.",
                status: "error",
            });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 2000);
            return;
        }

        const payload = {
            to: formData.get("email"), // email amministrazione
            from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Simulatore Quiz Concorsi", //nome sito
            replyTo: formData.get("email"), //email inserita dall'utente
            subject: "Benvenuto in Simulatore Quiz Concorsi",
            message: `<p>Salve ${formData.get("nome")} ${formData.get("cognome")}, </p><p>
                        Maggioli ti dà il benvenuto al nostro portale Simulatore Quiz Concorsi.<br> .</p>
                        <p>Per abilitare il tuo account è necessario cliccare sul link sottostante:<br>
                        ${BASE_URL}/activate/${res.data}</p><br>
                        <p>Buona navigazione,<br>
                        Lo staff di Maggioli Editore.</p>`,
            privacy: {
                ...data,
            },
            params: {
                ...emailParams,
            },
        };

        handleLoader.setLoaderVisible(true);
        sendDataToSubscriptions(payload, (result: any) => {
            handleLoader.setLoaderVisible(false);
            if (result.response_status) {
                setSubmit({
                    text:
                        "Account creato con successo! E' necessario confermare la registrazione cliccando sul link ricevuto per email. " +
                        "Per sicurezza controllare anche nella posta indesiderata (spam).",
                    status: "success",
                });
            }
        });
    };

    const privacyFields = () => {
        return form.privacy.map((elem, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={{ __html: elem.description }} />
                    {elem.fields.map((item, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    return (
        <Grid className="bg-adjust-tone-17 rounded-3xl">
            {form !== null && (
                <Form onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)} className="bg-adjust-tone-17 p-10 rounded-2xl">
                    <H3 className="-mt-5">Registrati</H3>
                    <Banner className="rounded-3xl -mt-5">
                        Registrati o accedi per poter eseguire le simulazioni delle prove.
                        <br />
                        <b>Le credenziali di accesso sono le stesse di approfondimenti.maggiolicloud.it</b>
                    </Banner>
                    {form.fields
                        .filter((elem) => elem.type !== "hidden")
                        .map((elem, index) => {
                            return elem.type !== "password" ? (
                                <InputText key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                            ) : (
                                <InputPassword key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                            );
                        })}

                    <Select label="A quali concorsi sei interessato?" value={ambito} onChange={(e) => setAmbito(e.target.value)}>
                        <BackofficeSelectOption value="">Scegli un'opzione...</BackofficeSelectOption>
                        {ambiti.map((a) => {
                            return <BackofficeSelectOption value={a}>{a}</BackofficeSelectOption>;
                        })}
                    </Select>

                    {form.fields
                        .filter((elem) => elem.type === "hidden")
                        .map((elem, index) => (
                            <input key={index} name={elem.name} value={elem.options.value} type={elem.type} />
                        ))}
                    <input name="trattamento" value="1" type="hidden" />
                    <input name="servizio" value={form.servizio} type="hidden" />
                    <Detail htmlTag={"div"}>
                        Cliccando su "Invia" dichiari di aver letto la
                        <a href="https://privacy.maggiolicloud.it/privacy/simulatorequizconcorsiit"> privacy policy.</a>
                    </Detail>

                    {privacyFields()}
                    {submit.status !== "" ? (
                        <Banner status={submit.status} className="rounded-xl">
                            <Detail>{submit.text}</Detail>
                        </Banner>
                    ) : (
                        <div>
                            <Button variant={submit.status} type="submit">
                                {submit.text}
                            </Button>
                        </div>
                    )}
                </Form>
            )}
        </Grid>
    );
}
