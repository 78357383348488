import '../Home/Home.css'
import VolumeRelated from '../../fragments/VolumeRelated'
import FavouriteQuizzes from '../../fragments/FavouriteQuizzes'
import QuizList from '../../components/QuizList'
import { ILoader } from '../../interfaces/ILoader'
import { useState } from 'react'
import { ITest } from '../../interfaces/ITest'
import { IFavManager } from '../../interfaces/IFavManager'

interface Props {
	handleLoader: ILoader,
}

const HomeLogged: React.FC <Props> = ({ handleLoader }: Props) : JSX.Element => {
  
  const savedFav: ITest[] = JSON.parse(localStorage.getItem('favourites'));
  const [fav, setFav] = useState<ITest[]>(savedFav !== null ? savedFav : []);

  const favManager: IFavManager = {
    fav: fav,
    setFav: e => setFav(e)
  }
  
  return <main>
    {/* <UserControlPanel/> */}
    { fav.length > 0 && <FavouriteQuizzes favManager={favManager}/> }
    <VolumeRelated/>
    <QuizList favManager={favManager} handleLoader={handleLoader}/>
  </main>
}

export default HomeLogged;
