import {
  H5,
  Icon,
  Image,
  LabelParagraph,
} from '@maggioli-design-system/react'
import clsx from 'clsx'
import answerVariant from './variant.json'

type AnswerStatusType =
  | 'answered'
  | 'correct'
  | 'default'
  | 'not-user-answered'
  | 'wrong'

interface IAnswerBadge {
  className?: string,
  status?: AnswerStatusType,
}

const AnswerBadge: React.FC <IAnswerBadge> = ({
  status,
  className,
}: IAnswerBadge): JSX.Element =>
  <LabelParagraph className={clsx(className, answerVariant[status].badge, 'absolute flex leading-none px-2 -mt-6 -mr-6 py-2 rounded-full')}>
    { status === 'wrong' && 'Sbagliata' }
    { status === 'correct' && 'Corretta' }
  </LabelParagraph>

interface IAnswer {
  answered?: boolean,
  children?: React.ReactNode,
  letter?: string,
  src?: string,
  status?: AnswerStatusType,
  boxHeight?: string
}

const Answer: React.FC <IAnswer> = ({
  children,
  answered,
  src,
  letter,
  status = 'default',
  boxHeight = ''
}: IAnswer): JSX.Element => {

  return <div className={clsx(
    answerVariant[status].background,
    answerVariant[status].border,
    'flex flex-col gap-0 items-center min-h-10 relative rounded-3xl',
    boxHeight
    )}>
      { src &&
        <div className="p-2 w-52">
          <Image className="rounded-2xl" src={ src }/>
        </div>
      }
      <div className="flex items-center gap-4 px-3 py-3 flex-grow w-full">
        <H5 className={clsx(answerVariant[status].letter, 'flex flex-shrink-0 h-8 items-center justify-center rounded-full uppercase w-8')}>
          { !answered || status === "not-user-answered" || status === 'answered' ? letter : <Icon name={answerVariant[status].icon}/> }
        </H5>
        <div className="flex-grow gap-2 grid">
          <H5><p dangerouslySetInnerHTML={{ __html: children.toString() }}></p></H5>
          { answered && status !== "not-user-answered" && status !== "answered" && <AnswerBadge className={clsx(src ? 'right-4 top-4' : 'right-2 top-2')} status={status}/> }
        </div>
      </div>
  </div>
}

export default Answer
