import {
	Detail,
	Grid, 
	H2, 
} from '@maggioli-design-system/react'

import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';

export default function LoginPage(): ReactElement {
    
    return (
        <div className="bg-adjust-tone-19 py-12 px-4 mobile:pt-4">
			<Grid className="gap-10 view-limit grid-tmpl-home mobile:grid-cols-1">
				<Grid className="gap-0 mobile:hidden" rows="fit-vertically">
					<H2 className="mb-4">Accedi al Simulatore QuizConcorsi</H2>
                    <Detail>
                        Per accedere al portale è necessario essere registrati su approfondimenti.maggiolicloud.it
                        ed utilizzare le relative credenziali. <br />
                        Per registrarti <Link to='/register'><b>clicca qui.</b></Link>
                    </Detail>

				</Grid>
				<Grid columns="2" className="gap-10 mobile:gap-4" rows="fit-vertically">
                    <LoginForm/>
				</Grid>
			</Grid>
	    </div>
    )
}
