import Connection from "../utils/Connection";
import { EXPRESS_URL } from "../utils/Macros";

export async function getUserHistory(testID: string, customerID: string) {
    return await Connection.get(`${EXPRESS_URL}customerHistories/getSimulationHistory/${testID}/${customerID}`);
}

export async function checkFreeSimulations(customerID: string) {
    return await Connection.get(`${EXPRESS_URL}customerHistories/checkFree/${customerID}`);
}

export async function getQuestionSet(customerHistoryID: string) {
    return await Connection.get(`${EXPRESS_URL}customerHistories/getQuestionSet/${customerHistoryID}`);
}

export async function deleteCustomerHistory(customerHistoryID: string) {
    return await Connection.delete(`${EXPRESS_URL}customerHistories/deleteCompletedSimulation/${customerHistoryID}`);
}