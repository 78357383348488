import {
    BenchmarkBar,
    Banner,
    Button,
    Card,
    Detail,
    Grid,
    H2,
    H4,
    H5,
    Icon,
    InputText,
    Row,
    LabelCaption,
    Modal,
} from "@maggioli-design-system/react";
import { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
// import BannerModuli from "../../components/BannerModuli/BannerModuli";
import LoginForm from "../../components/LoginForm/LoginForm";
import { maxFreeSimulations } from "../../components/QuickSearch/QuickSearch";
import QuizDetail from "../../components/QuizDetail";
import Registerform from "../../components/Registerform/Registerform";
import SimulationConfig from "../../components/SimulationConfig/SimulationConfig";
import SimulationDetails from "../../components/SimulationDetails";
import SimulationHistoryItem from "../../components/SimulationHistoryItem";
import { IHistory } from "../../interfaces/ICustomerHistory";
import { ILoader } from "../../interfaces/ILoader";
import { ISimulation } from "../../interfaces/ISimulation";
import { ITest } from "../../interfaces/ITest";
import { checkUserAuthorization, getBookCovers, loginByPasscode } from "../../services/ApproService";
import { checkFreeSimulations, getUserHistory } from "../../services/CustomerHistoryService";
import { checkProgress } from "../../services/CustomerRecordService";
import { getFlatSimulation } from "../../services/SimulationService";
import { getTestByID } from "../../services/TestService";
import { getCookie, saveLogin } from "../../utils/Functions";
import { TailSpin } from "react-loader-spinner";

interface Props {
    handleLoader: ILoader;
    isWorker: boolean;
}

interface ISimulations {
    all: ISimulation[];
    filtered: ISimulation[];
}

const QuizPage = ({ handleLoader, isWorker }: Props): JSX.Element => {
    const { id, passCode } = useParams<Record<string, string>>();
    const history = useNavigate();
    const loggedUser = JSON.parse(getCookie("loggedUser"));
    const IS_LOGGED: boolean = loggedUser !== null;

    const [test, setTest] = useState<ITest>(null);
    const [simulations, setSimulations] = useState<ISimulations>({ all: [], filtered: [] });
    const [selectedSimulation, setSelectedSimulation] = useState<ISimulation>(null);
    const [configVisible, setConfigvisible] = useState<boolean>(false);
    const [customConfig, setCustomConfig] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [customerHistory, setCustomerHistory] = useState<IHistory[]>([]);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [bookCover, setBookCover] = useState<string>("");
    const [freeDone, setFreeDone] = useState<boolean>(false);
    const [freeSimu, setFreeSimu] = useState<boolean>(false);
    const [loadingHistory, setLoadingHistory] = useState<boolean>(false);

    useEffect(() => {
        setSimulations((p) => ({
            ...p,
            filtered: p.all.filter((e) => e.title.toLowerCase().includes(searchText)),
        }));
    }, [searchText]);

    useEffect(() => {
        passCode !== undefined && loginByCode();
        loadTest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWorker]);

    const loginByCode = async () => {
        handleLoader.setLoaderVisible(true);
        const res = await loginByPasscode(passCode);
        if (res.status) {
            saveLogin(res);
            const path: string[] = window.location.href.split("/");
            window.setTimeout(() => window.location.replace(path.slice(0, path.length - 1).join("/")), 0);
        } else {
            handleLoader.setLoaderVisible(false);
        }
    };

    const checkFree = async () => {
        const res = await checkFreeSimulations(JSON.parse(getCookie("loggedUser")).id);
        const data = await res.json();
        if (data.status) {
            setFreeDone(data.data >= maxFreeSimulations && !isWorker);
        }
    };

    const loadTest = async () => {
        handleLoader.setLoaderVisible(true);
        const resTest = await getTestByID(id);
        if (!resTest.ok) {
            return;
        }

        let dataTest = await resTest.json();
        const linkedVolumes: string[] = dataTest.linked_volumes;
        dataTest = dataTest.data;
        setTest(dataTest);

        if (dataTest === null) {
            handleLoader.setLoaderVisible(false);
            return;
        }

        if (dataTest.ean !== "") {
            const ress = await getBookCovers(dataTest.ean);
            ress.status && ress.data.length > 0 && setBookCover(ress.data.pop().guid);
        }

        const foundFreeSimu: boolean = dataTest.simulations.filter((s) => s.settings.data.free === "1").length > 0;
        setFreeSimu(foundFreeSimu);
        if (!IS_LOGGED) {
            handleLoader.setLoaderVisible(false);
            return;
        }

        let checkAuth: boolean = false;
        if (linkedVolumes.length > 0) {
            const resAuth = await checkUserAuthorization(loggedUser.id, linkedVolumes.join(","));
            checkAuth = resAuth.status;
        }
        setIsAuth(checkAuth || isWorker);

        foundFreeSimu && (await checkFree());

        const temp = [];
        dataTest.simulations
            .filter((s) => s.enable === "1")
            .forEach(async (s) => {
                const res = await checkProgress(s.id, JSON.parse(getCookie("loggedUser")).id);
                const resData = await res.json();
                const flatID: string = resData.data;

                let startDate: string = "";
                if (flatID !== "") {
                    const resSimu = await getFlatSimulation(flatID);
                    if (!resSimu.ok) {
                        return;
                    }
                    const dataSimu = await resSimu.json();
                    if (!dataSimu) {
                        handleLoader.setLoaderVisible(false);
                        return;
                    }
                    startDate = dataSimu.start_time;
                }

                temp.push({
                    ...s,
                    paused: flatID,
                    started: startDate,
                });

                if (temp.length === dataTest.simulations.filter((s) => s.enable === "1").length) {
                    setSimulations({ all: temp, filtered: temp });
                }
            });
        handleLoader.setLoaderVisible(false);
        loadHistory();
    };

    const loadHistory = async () => {
        setLoadingHistory(true);
        const resHistory = await getUserHistory(id, JSON.parse(getCookie("loggedUser")).id);
        setLoadingHistory(false);
        if (!resHistory.ok) {
            return;
        }
        const dataHistory = await resHistory.json();
        if (dataHistory) {
            setCustomerHistory(dataHistory);
        }
    };

    const selectSimulation = async (data: ISimulation) => {
        setConfigvisible(true);
        setSelectedSimulation(data);
    };

    const simulationFields = () => {
        if (simulations.all.filter((s) => s.deleted_at === null).length === 0) {
            return (
                <Banner status="warning" className="rounded-2xl">
                    <Detail>Non sono disponibili simulazioni per questa prova.</Detail>
                </Banner>
            );
        }
        if (simulations.filtered.filter((s) => s.deleted_at === null).length === 0) {
            return (
                <Banner status="warning" className="rounded-2xl">
                    <Detail>Non sono state trovate simulazioni.</Detail>
                </Banner>
            );
        }
        return simulations.filtered
            .filter((s) => s.deleted_at === null)
            .map((e, i) => (
                <SimulationDetails
                    freeDone={!isAuth && freeDone && e.settings.data.free === "1"}
                    data={e}
                    key={i}
                    onClick={() => selectSimulation(e)}
                />
            ));
    };

    const freeSimulationFields = () => {
        return simulations.filtered
            .filter((s) => s.settings.data.free === "1")
            .map((e, i) => <SimulationDetails freeDone={freeDone} data={e} key={i} onClick={() => selectSimulation(e)} />);
    };

    if (!handleLoader.loaderVisible && test === null) {
        return (
            <div className="bg-adjust-tone py-12 px-4 mobile:pt-4">
                <div className="view-limit">
                    <H2 className="text-center">404 - Prova non trovata</H2>
                    <Button icon="action-back" variant="secondary-outline" className="mx-auto mt-10" onClick={() => history(-1)}>
                        Torna indietro
                    </Button>
                </div>
            </div>
        );
    }

    if (test === null) {
        return <div></div>;
    }

    const loginPlaceholder = () => {
        return (
            <Card className="gap-0 mobile:px-0">
                {!freeSimu && (
                    <Banner className="rounded-3xl">
                        Registrati o accedi per poter eseguire le simulazioni delle prove.
                        <br />
                        <b>Le credenziali di accesso sono le stesse di approfondimenti.maggiolicloud.it</b>
                    </Banner>
                )}
                <div className="mt-3">{freeSimu ? <Registerform free={true} handleLoader={handleLoader} /> : <LoginForm />}</div>
            </Card>
        );
    };

    const notAuthPlaceholder = () => {
        const linkIsPresent: boolean = test.link !== null && test.link !== "";
        if (simulations.filtered.filter((s) => s.settings.data.free === "1").length > 0) {
            return (
                <Grid>
                    {linkIsPresent && (
                        <Card className={`bg-adjust-tone-19 gap-0 overflow-hidden shadow hover:shadow-lg`}>
                            <div>
                                Per l'acquisto del volume cartaceo visita il sito di{" "}
                                <a className="text-brand-maggioli-04 underline" href={test.link} target="_blank" rel="noreferrer">
                                    Maggioli Editore.
                                </a>
                            </div>
                        </Card>
                    )}

                    <H4 className="mobile:hidden my-auto">Simulazioni gratuite</H4>
                    {freeSimulationFields()}
                </Grid>
            );
        }

        return (
            <Card
                className={`bg-adjust-tone-19 quiz-card gap-0 p-5 overflow-hidden shadow hover:shadow-lg ${
                    linkIsPresent ? "mobile:h-32 h-24" : "mobile:h-20 h-16"
                }`}
            >
                Non hai riscattato alcun codice per eseguire le simulazioni di questa prova.
                {linkIsPresent && (
                    <div>
                        Per l'acquisto del volume cartaceo visita il sito di{" "}
                        <a className="text-brand-maggioli-04 underline" href={test.link} target="_blank" rel="noreferrer">
                            Maggioli Editore.
                        </a>
                    </div>
                )}
            </Card>
        );
    };

    const unpublishedPlaceholder = () => {
        return (
            <Card className="bg-status-warning-19 quiz-card gap-0 p-6 overflow-hidden shadow hover:shadow-lg h-24">
                <b>Attenzione </b>La prova risulta non attiva!
            </Card>
        );
    };

    const customHandler = {
        customConfig: customConfig,
        setCustomConfig: setCustomConfig,
    };

    if (!IS_LOGGED || test.enable === "0") {
        return (
            <div className="bg-adjust-tone py-12 px-4 mobile:pt-4">
                <div className="view-limit">
                    <Button icon="action-back" variant="secondary-outline" onClick={() => history(-1)}>
                        Torna indietro
                    </Button>

                    {/* {!IS_LOGGED && <BannerModuli />} */}
                    <Grid className="gap-10 grid-cols-2 mt-10 tablet-max:grid-cols-1">
                        <QuizDetail data={test} bookCover={bookCover} />

                        {!IS_LOGGED && loginPlaceholder()}

                        {test.enable === "0" && IS_LOGGED && unpublishedPlaceholder()}
                    </Grid>
                </div>
            </div>
        );
    }

    const themesPreparation = () => {
        const themes = [];
        const questions = customerHistory.map((c) => JSON.parse(c.json_data).simulation.questions).flat();
        const answers = customerHistory.map((c) => JSON.parse(c.json_data).answers).flat();
        customerHistory
            .map((c) => JSON.parse(c.json_data).simulation.settings.data.themes)
            .flat()
            .forEach((e) => !themes.map((t) => t.id).includes(e.id) && themes.push(e));
        themes.forEach((t) => {
            const temp = questions.filter((q) => q.theme_id === t.id);
            t.number = temp.length;
            t.right = answers.filter((a) => a.right).filter((a) => temp.map((q) => q.id).includes(a.questionID)).length;
        });

        return themes;
    };

    const dbPreparation = () => {
        const dbs = [];
        const jsonDatas = customerHistory.map((c) => JSON.parse(c.json_data)).flat();
        const questions = jsonDatas.map((c) => c.simulation.questions).flat();
        jsonDatas
            .map((c) => c.simulation.settings.data)
            .flat()
            .forEach((e) => e.data_bank !== 0 && !dbs.map((t) => t.title).includes(e.data_bank) && dbs.push({ title: e.data_bank }));

        dbs.forEach((t) => {
            const temp = questions.filter((q) => q.type === t.title.toString());
            t.number = temp.length;
            t.right = jsonDatas.filter((d) => d.simulation.settings.data.data_bank === t.title)[0].answers.filter((a) => a.right).length;
        });

        return dbs;
    };

    const deleteUpdate = (id: string) => {
        setCustomerHistory(customerHistory.filter((s) => s.id.toString() !== id));
    };

    return (
        <div className="bg-adjust-tone py-12 px-4 mobile:pt-4">
            <div className="view-limit">
                <Button icon="action-back" variant="secondary-outline" onClick={() => history(-1)}>
                    Torna indietro
                </Button>
                <Grid className="gap-10 grid-cols-2 mt-10 tablet-max:grid-cols-1">
                    <Grid rows="fit-vertically">
                        <QuizDetail data={test} bookCover={bookCover} />
                    </Grid>

                    <div>
                        <Grid className="gap-4" rows="fit-vertically">
                            {isAuth ? (
                                <Grid className="gap-4">
                                    <Grid columns="2">
                                        <H4 className="mobile:hidden my-auto">Simulazioni</H4>
                                        <InputText
                                            className=" p-1"
                                            placeholder="Cerca le simulazioni..."
                                            icon="data-search"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                                        />
                                    </Grid>

                                    {simulationFields()}
                                </Grid>
                            ) : (
                                notAuthPlaceholder()
                            )}

                            {customerHistory.length > 0 && (
                                <Card className="bg-adjust-tone-20 p-4">
                                    <H5 className="-mb-3">Il tuo livello di preparazione</H5>
                                    <Grid columns="2">
                                        {themesPreparation()
                                            .filter((t) => t.number > 0)
                                            .map((t, i) => {
                                                const percentage: number = Math.round((t.right / t.number) * 100);
                                                return (
                                                    <BenchmarkBar key={i} autoColor progress={percentage} progressText={`${percentage} %	`}>
                                                        {t.title}
                                                    </BenchmarkBar>
                                                );
                                            })}
                                        {dbPreparation()
                                            .filter((t) => t.number > 0)
                                            .map((t, i) => {
                                                const percentage: number = Math.round((t.right / t.number) * 100);
                                                return (
                                                    <BenchmarkBar key={i} autoColor progress={percentage} progressText={`${percentage} %	`}>
                                                        {`Bancadati - ${t.title.toString()}`}
                                                    </BenchmarkBar>
                                                );
                                            })}
                                    </Grid>
                                    <Row>
                                        <Icon name="status-info"></Icon>
                                        <Detail>Percentuale di risposte esatte per ogni materia</Detail>
                                    </Row>
                                </Card>
                            )}

                            {customerHistory.length > 0 && (
                                <Grid className="flex gap-2">
                                    <LabelCaption>Simulazione precedente</LabelCaption>
                                    <div className="flex gap-4">
                                        <SimulationHistoryItem simulations={customerHistory} testID={id} deleteUpdate={deleteUpdate} />
                                    </div>
                                </Grid>
                            )}

                            {loadingHistory && (
                                <div className="mx-auto">
                                    <TailSpin color="#0041B9" />
                                </div>
                            )}

                            {isAuth && customerHistory.length === 0 && simulations.all.length > 0 && !loadingHistory && (
                                <Banner status="info" className="rounded-2xl">
                                    <Detail>Non hai ancora completato alcuna simulazione per questa prova.</Detail>
                                </Banner>
                            )}
                        </Grid>
                    </div>

                    <Modal
                        visible={configVisible}
                        footer={false}
                        maxWidth="800"
                        onCancel={() => {
                            setConfigvisible(false);
                            setCustomConfig(false);
                        }}
                        confirmButton="Inizia simulazione"
                    >
                        <SimulationConfig testID={id} data={selectedSimulation} custom={customHandler} />
                    </Modal>
                </Grid>
            </div>
        </div>
    );
};

export default QuizPage;
