export const verdict = (pointsMade: number, pointsNeeded: number): string => {

  if (pointsMade >= pointsNeeded) {
    return 'good'
  }

  // if (pointsMade < pointsNeeded && pointsMade >= pointsNeeded - 2) {
  //   return 'almost'
  // }

  return 'bad'
}

interface IVerdictText {
  title: string,
  text: string,
}

export const verdictText = (pointsMade: number, pointsNeeded: number): IVerdictText => {

  if (pointsMade >= pointsNeeded) {
    return {
      title: 'Superata',
      text: 'Ottimo lavoro'
    }
  }

  // if (pointsMade < pointsNeeded && pointsMade >= pointsNeeded - 2) {
  //   return {
  //     title: 'Non superata',
  //     text: 'Per un soffio'
  //   }
  // }

  return {
    title: 'Non superata',
    text: 'È necessario un bel ripasso'
  }
}
