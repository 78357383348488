class Connection {
    username = process.env.REACT_APP_EXPRESS_USER;
    password = process.env.REACT_APP_EXPRESS_PASS;
    headers = new Headers();

    constructor() {
        this.headers.set("Authorization", "Basic " + btoa(`${this.username}:${this.password}`));
        this.headers.set("Content-Type", "application/json");
    }

    async post(url, param) {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: JSON.stringify(param),
        });
        return response;
    }

    async get(url) {
        const response = await fetch(url, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
        });

        return response;
    }

    async put(url, param) {
        const response = await fetch(url, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: JSON.stringify(param),
        });
        return response;
    }

    async delete(url) {
        const response = await fetch(url, {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
        });
        return response;
    }

    async upload(url, param) {
        this.headers.delete("Content-type");
        this.headers.set("Accept", "*/*");
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: param,
        });
        this.headers.set("Content-Type", "application/json");
        return response;
    }

    async uploadEdit(url, param) {
        this.headers.delete("Content-type");
        this.headers.set("Accept", "*/*");
        const response = await fetch(url, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: param,
        });
        this.headers.set("Content-Type", "application/json");
        return response;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Connection();
