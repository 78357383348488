import React, { ReactElement } from 'react'
import {
    H3, 
    Grid,
} from '@maggioli-design-system/react'
import { ILoader } from '../../interfaces/ILoader';
import ContactForm from '../../components/ContactForm';

interface Props {
    handleLoader: ILoader
}

export default function Contact({ handleLoader }: Props): ReactElement {
    return (
        <div className="padding-normal bg-adjust-tone-19">
            <H3 className="text-center pt-6">Contatti</H3>
            <Grid columns="3">
                <div></div>
                <div className="bg-adjust-tone-17 p-8 mb-10 rounded-3xl">
                    <ContactForm handleLoader={handleLoader}/>
                </div>
                <div></div>
            </Grid>
        </div>
    )
}
