import Connection from "../utils/Connection";
import { EXPRESS_URL } from "../utils/Macros";

export async function getSimulation(simuID: string) {
    return await Connection.get(`${EXPRESS_URL}simulations/${simuID}`)
}

export async function getFlatSimulation(simuID: string) {
    return await Connection.get(`${EXPRESS_URL}app/${simuID}`)
}

export async function createFlatSimulation(simuObject: any) {
    return await Connection.post(`${EXPRESS_URL}app/`, simuObject)
}

export async function endSimulation(simuObject: any) {
    return await Connection.post(`${EXPRESS_URL}customerHistories/`, simuObject)
}