export const isCustom = (simulation): boolean => {
    return simulation !== null && simulation.custom !== null;
}

export const noStop = (simulation): boolean => {
    return isCustom(simulation) && simulation.custom.no_stop;
}

export const showRight = (simulation): boolean => {
    return isCustom(simulation) && simulation.custom.show_right;
}

export const skipQuestions = (simulation): boolean => {
    return !isCustom(simulation) || simulation.custom.skip_questions;
}

export const autoNextQuestion = (simulation): boolean => {
    return simulation.custom !== null && parseInt(simulation.custom.auto_skip) > 0;
}
