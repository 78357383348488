import React, { useState, ChangeEvent } from 'react'
import { 
    Button,
    H3,
    Form,
    InputPassword,
} from "@maggioli-design-system/react";
import { useParams } from 'react-router-dom';
import { ISubmit } from '../../interfaces/ISubmit';
import { ILoader } from '../../interfaces/ILoader';
import { resetPassword } from '../../services/ApproService';

interface Props {
    handleLoader: ILoader,
}

export default function ResetPassword({ handleLoader }: Props) {

    const initText: string = 'Conferma';
    const initStatus: string = '';

    const { code } = useParams<{ code: string }>();

    const [submit, setSubmit] = useState<ISubmit>({ text: 'Conferma', status: '' });
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== password2 || password.length < 6 || password2.length < 6) {
            alert('Le password devono coincidere (lunghezza min. 6 caratteri)');
            return;
        }

        handleLoader.setLoaderVisible(true);
        const res = await resetPassword(password, code);
        handleLoader.setLoaderVisible(false);
        if (!res.status) {
            setSubmit({ text: res.message, status: 'error' });
            window.setTimeout(() => setSubmit({ status: initStatus, text: initText }), 2000);
            handleLoader.setLoaderVisible(false);
            return;
        }        
        setSubmit({ text: 'Password cambiata con successo!', status: 'success' });
        window.setTimeout(() => { window.location.href = '/login' }, 2000);
    }

    return (
        <div className="bg-adjust-tone-19">
            <div className="desktop:px-80 tablet-max:px-8 py-20 view-limit">
                <H3 className="text-center vertical-padding-xxsmall">Nuova password</H3>
                <Form onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)} className="bg-adjust-tone-17 p-10 rounded-xl shadow-lg">
                    
                    <InputPassword value={password} label="Password" placeholder="Password..." 
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword(e.target.value)}/>

                    <InputPassword value={password2} label="Ripeti password" placeholder="Ripeti password..." 
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword2(e.target.value)}/>

                    <div><Button variant={submit.status} type="submit">{submit.text}</Button></div>
                </Form> 
            </div>
        </div>
    )
}