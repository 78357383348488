import React, { useState } from 'react'
import { 
    Button,
    Card,
    H2,
    Grid, 
    InputText, 
} from "@maggioli-design-system/react";
import { ISubmit } from '../../interfaces/ISubmit';
import { ILoader } from '../../interfaces/ILoader';
import { BASE_URL, emailParams } from '../../utils/Macros';
import { passwordRecovery } from '../../services/ApproService';
import { sendDataNoPrivacy } from '../../services/PrivacyService';

interface Props {
    handleLoader: ILoader,
}

export default function RecoverPassword({ handleLoader }: Props) {

    const initText: string = 'Recupera password';
    const initStatus: string = '';

    const [email, setEmail] = useState<string>('');
    const [submit, setSubmit] = useState<ISubmit>({ text: initText, status: initStatus });

    const onClickRecoverPassword = async () => {
        if (email === '' || submit.status !== '') {
            return;
        }

        handleLoader.setLoaderVisible(true);
        const res = await passwordRecovery(email);
        if (!res.status) {
            setSubmit({ text: res.message, status: 'error' });
            window.setTimeout(() => setSubmit({ status: initStatus, text: initText }), 2000);
            handleLoader.setLoaderVisible(false);
        }
        sendRecoverEmail(res.data);
    }

    const sendRecoverEmail = async (code: string) => {
        const payload = {
            to: email, // email amministrazione
            from: 'no-reply-siti-web@maggiolieditore.it', //email no replay sito
            fromName: 'Simulatore Quiz Concorsi', //nome sito
            replyTo: 'no-reply-siti-web@maggiolieditore.it', //email inserita dall'utente
            subject: 'Recupero password Simulatore Quiz Concorsi',
            message: `<p>Salve, </p><p>se ha ricevuto questa email è perchè qualcuno 
                        ha richiesto il cambio password. <br> Se non si è sicuri di quello che 
                        si sta facendo o se non si ha richiesto nessuna azione, eliminate questa 
                        stessa email.</p><p>In alternativa per procedere al cambio password, 
                        cliccare sul link sottostante:
                        <br><a href="${BASE_URL}/resetPassword/${code}">Clicca qui</a></p>
                        <p>Nota: il link è valido solo nella giornata odierna.</p>
                        <p>Distinti saluti<br>Lo staff di Maggioli Editore</p>`,
            params: {
                ...emailParams
            },
        }

        const res = await sendDataNoPrivacy(payload);
        handleLoader.setLoaderVisible(false);
        const data = await res.json();
        if (data.status) {
            setSubmit({ text: 'Richiesta inviata con successo!', status: 'success' });
        }
    }

    return (
        <div className="bg-adjust-tone-19 py-12 mobile:pt-4">
            <Grid className="gap-1 mobile:gap-4 view-limit desktop:px-60 tablet-max:px-8 mobile:grid-cols-1" rows="fit-vertically">
                <H2 className="mx-auto">Recupera password</H2>
                <Card className="shadow-lg bg-adjust-tone-18">
                    Nel caso tu abbia dimenticato la tua password, potrai impostarne una nuova. <br />
                    Una volta avviata la procedura di recupero password, riceverai una email con un link di accesso alla pagina di 
                    reimpostazione della password. <br />
                    Nota: controlla anche la posta indesiderata.
                
                    <InputText label="Email" placeholder="Inserisci la tua email di registrazione..." value={email} 
                            onChange={e => setEmail(e.target.value)}/>
                    <div><Button variant={submit.status} onClick={onClickRecoverPassword}>{submit.text}</Button></div>
                </Card>
            </Grid>
        </div>
    )
}