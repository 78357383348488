import { 
	Grid, 
	H2, 
    H5,
} from '@maggioli-design-system/react'

import { Link } from 'react-router-dom';
import React, { ReactElement } from 'react'

export default function NotFound(): ReactElement {

    return (
        <div className="bg-adjust-tone-19 py-12 px-4 mobile:pt-4">
			<Grid className="text-center gap-10 view-limit mobile:grid-cols-1">
				<H2 className="vertical-padding-large">404 - Pagina non trovata!</H2>
                <Link to="/home/1">
                    <H5 style={{ color: 'darkBlue' }} >Torna alla home</H5>
                </Link>
			</Grid>
	    </div>
    )
}
