import { getCookie } from "../utils/Functions";
import { APPRO_URL } from "../utils/Macros";

const USER = process.env.REACT_APP_APPRO_USER;
const PASS = process.env.REACT_APP_APPRO_PASS;
const BASE_URL: string = APPRO_URL + "core/auth/api/v2/";
const headers = new Headers();

export async function login(email: string, password: string) {
    const url: string = BASE_URL + "post/users/logincac";
    const payload = {
        "email": email,
        "password": password,
        "backoffice": false,
        "justCac": "0",
    };

    setAuthorization();

    const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function loginByPasscode(code: string) {
    const url: string = BASE_URL + "post/users/loginByPasscode";
    const payload = {
        "code": code,
    };

    setAuthorization();

    const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function newUser(name: string, surname: string, email: string, password: string, password2: string) {
    const url: string = BASE_URL + "post/users/newUser";
    const payload = {
        "name": name,
        "surname": surname,
        "email": email,
        "password": password,
        "password2": password2,
    };

    setAuthorization();

    const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function activateUser(passCode: string) {
    const payload = { "password_code": passCode };

    setAuthorization();

    const response = await fetch(BASE_URL + "put/users/activate", {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function redeemCode(code: string) {
    const user = JSON.parse(getCookie("loggedUser"));
    const payload = {
        user_id: user.id,
        code: code,
    };

    setAuthorization();

    const response = await fetch(BASE_URL + "post/books/redeemCode", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function getBookCovers(data: string) {
    setAuthorization();

    const response = await fetch(BASE_URL + `get/books/getCovers/${data}`, {
        method: "GET",
        headers: headers,
    });
    return response.json();
}

export async function checkUserAuthorization(userID: string, ids: string) {
    const url: string = BASE_URL + `get/users/checkUserAndQuiz/${userID}/${ids}`;

    setAuthorization();

    const response = await fetch(url, {
        method: "GET",
        headers: headers,
    });
    return response.json();
}

export async function passwordRecovery(email: string) {
    const payload = { "email": email };

    setAuthorization();

    const response = await fetch(BASE_URL + "put/users/recoveryPassword", {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function resetPassword(password: string, code: string) {
    const payload = {
        "password": password,
        "code": code,
    };

    setAuthorization();

    const response = await fetch(BASE_URL + "put/users/resetPassword", {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(payload),
    });
    return response.json();
}

const setAuthorization = () => {
    headers.set("Authorization", "Basic " + btoa(`${USER}:${PASS}`));
    headers.set("Content-Type", "application/json");
};
