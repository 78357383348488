import { ITest } from "../interfaces/ITest";

export const onChangeFav = (alreadyFav: boolean, elem: ITest) => {
    const favQuiz: ITest[] = JSON.parse(localStorage.getItem("favourites"));
    if (alreadyFav) {
        localStorage.setItem("favourites", JSON.stringify(favQuiz.filter((e) => e.id !== elem.id)));
    } else {
        if (favQuiz === null) {
            localStorage.setItem("favourites", JSON.stringify([elem]));
        } else {
            favQuiz.push(elem);
            localStorage.setItem("favourites", JSON.stringify(favQuiz));
        }
    }
};

// scrolla in cima alla pagina
export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

// esegue il logout
export const onClickLogout = () => {
    localStorage.clear();
    setCookie("loggedUser", "", -1);
    window.location.href = "/home/1";
};

// converte in minuti in formato orario
export const timeConvert = (num: number): string => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    const hourText = hours > 1 ? "ore" : "ora";
    const minutesText = minutes === 1 ? "minuto" : "minuti";
    return `${hours > 0 ? hours : ""} ${hours > 0 ? hourText : ""} ${minutes > 0 ? (hours > 0 ? "e " + minutes : minutes) : ""} ${
        minutes > 0 ? minutesText : ""
    }`.trim();
};

export const emailValidation = (email: string) => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!pattern.test(String(email).toLowerCase())) {
        return false;
    }
    return true;
};

export function setCookie(name: string, value: string, days: number = 360) {
    let expires: string = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string) {
    let nameEQ: string = name + "=";
    let ca: string[] = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c: string = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

export const fromMsToTime = (timeMs: number, extended: boolean = false) => {
    const minutes: number = Math.floor(timeMs / 60000);
    const seconds: number = parseInt(((timeMs % 60000) / 1000).toFixed(0));

    if (extended && seconds === 0) {
        return `${minutes} minuti`;
    }
    if (extended) {
        return `${minutes} minuti e ${seconds < 10 ? "0" : ""}${seconds} secondi`;
    } else {
        return `${minutes} m : ${seconds < 10 ? "0" : ""}${seconds} s`;
    }
};

export const fromSecToTime = (timeSec: number, extended: boolean = false) => {
    const minutes: number = Math.floor(timeSec / 60);
    const seconds: number = parseInt((timeSec % 60).toFixed(0));

    if (extended && seconds === 0) {
        return `${minutes} minuti`;
    }
    if (extended) {
        return `${minutes} minuti e ${seconds < 10 ? "0" : ""}${seconds} secondi`;
    } else {
        return `${minutes} m : ${seconds < 10 ? "0" : ""}${seconds} s`;
    }
};

export const fromMsToS = (timeMs: number) => {
    const seconds: number = parseInt(((timeMs % 60000) / 1000).toFixed(0));
    const text: string = seconds === 1 ? "secondo" : "secondi";
    return `${seconds} ${text}`;
};

export const getDifficulty = (questions) => {
    if (questions.length === 0) {
        return 0;
    }
    if (questions.filter((e) => e.level_id !== null).length < questions.length / 2) {
        return 0;
    }
    const maxDifficulty: number = questions.filter((e) => e.level_id !== null).length * 3;
    let tempDiff: number = 0;
    questions.filter((e) => e.level_id !== null).forEach((e) => (tempDiff += parseInt(e.level_id)));
    return (100 / maxDifficulty) * tempDiff;
};

export const getPointsDone = (simulation) => {
    const answers = simulation.answers;
    const config = simulation.simulation.settings.data;
    if (scorePerTheme(config)) {
        return config.themes.reduce((a, b) => a + b.number * b.right, 0);
    }

    const rightAnswers: number = answers.filter((e) => e.right).length;
    const wrongAnswers: number = answers.filter((e) => e.right === false).length;
    const skippedAnswers: number = answers.filter((e) => e.right === null).length;
    const score: number = rightAnswers * config.right + skippedAnswers * config.empty + wrongAnswers * config.wrong;
    return roundOneDecimal(score);
};

export const calculateScore = (data) => {
    let totalScore: number = 0;
    data.answers.forEach((e) => {
        const question = data.simulation.questions.filter((q) => q.id === e.questionID).pop();
        const theme = data.simulation.settings.data.themes.filter((t) => t.id === question.theme_id).pop();
        totalScore += e.right ? 1 * parseFloat(theme.right) : e.right === false ? 1 * parseFloat(theme.wrong) : 1 * parseFloat(theme.empty);
    });
    return roundOneDecimal(totalScore);
};

export const scorePerTheme = (data) => {
    return data.right === 0;
};

export const roundOneDecimal = (num: number) => {
    return Math.round(num * 10) / 10;
};

export const saveLogin = (res: IResLogin) => {
    setCookie(
        "loggedUser",
        JSON.stringify({
            id: res.user_id,
            email: res.email,
        }),
    );
};

interface IResLogin {
    user_id: string;
    email: string;
    data: string[];
}
