import Connection from "../utils/Connection";
import { EXPRESS_URL } from "../utils/Macros";

export async function saveCustomerRecord(data: any) {
    return await Connection.post(`${EXPRESS_URL}customerRecords/`, data);
}

export async function editCustomerRecord(customerRecordID: number, data: any) {
    return await Connection.put(`${EXPRESS_URL}customerRecords/${customerRecordID}`, data);
}

export async function getProgress(simulationFlatID: string, customerID: string) {
    return await Connection.get(`${EXPRESS_URL}customerRecords/${simulationFlatID}/${customerID}`);
}

export async function checkProgress(simulationFlatID: string, customerID: string) {
    return await Connection.get(`${EXPRESS_URL}customerRecords/check-started-simulation/${simulationFlatID}/${customerID}`);
}