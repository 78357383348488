import { 
    Toggler,
    Modal,
    Button,
    Paragraph
 } from '@maggioli-design-system/react';

import React from 'react'

interface IProps {
    icon: string,
    variant: string,
    confirm?: Function,
    confirmText?: string,
    text?: string,
    buttonText: string, 
    confirmVariant: string,
}

const CustomConfirmAlert = ({ icon, variant, confirm, confirmText, text, buttonText, confirmVariant }: IProps) => {

    return (
        <Toggler>
            <Toggler.Trigger>

                {/* Bottone che apre la modale */}
                <Button icon={icon} variant={variant} borderRadius="small" horizontalPadding="none">
                    {buttonText}
                </Button>
            </Toggler.Trigger>
            <Toggler.Content>
                
                {/* Modale */}
                <Modal position="center" confirmVariant={ confirmVariant === undefined ? 'error' : confirmVariant} footer={confirm !== undefined}
                    confirmButton={confirmText} onConfirm={confirm !== undefined ? confirm : () => {}} cancelVariant="primary-outline" 
                    maxWidth="600">
                    <Paragraph>{text}</Paragraph>
                </Modal>
            </Toggler.Content>
        </Toggler>
    )
}

export default CustomConfirmAlert;
