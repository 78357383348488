export function validateNumber(number: string) {
    return /^\d+$/.test(number) && number !== '' && parseInt(number) > 0;
}

export const emailValidation = (email: string) => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!pattern.test(String(email).toLowerCase())) {
        return false;
    }
    return true;
}

export const passwordValidation = (password: string, password2: string): boolean => {
    if (password.length < 6 || password2.length < 6) {
        return false;
    }
    if (password !== password2) {
        return false;
    }
    return true;
}