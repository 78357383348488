import { Button, Detail, Grid, H2, Row } from "@maggioli-design-system/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { ITest } from "../../interfaces/ITest";
import { onChangeFav } from "../../utils/Functions";
import { APPRO_IMAGE } from "../../utils/Macros";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface IQuizDetail {
    className?: string;
    bookCover?: string;
    data: ITest;
}

const QuizDetail: React.FC<IQuizDetail> = ({ className, bookCover = "", data }: IQuizDetail): JSX.Element => {
    const [fav, setFav] = useState<boolean>(false);

    useEffect(() => {
        const favQuiz: ITest[] = JSON.parse(localStorage.getItem("favourites"));
        setFav(favQuiz !== null && favQuiz.filter((e) => e.id === data.id).length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fav]);

    const onClickFav = () => {
        const temp = { ...data, num_simulations: data.simulations.length, image: bookCover !== "" ? bookCover : "" };
        onChangeFav(fav, temp);
        setFav(!fav);
    };

    return (
        <Grid className={clsx(className, "gap-2")} rows="fit-vertically">
            {fav !== null ? (
                <div className="flex items-start gap-2 mobile:flex-wrap">
                    {bookCover !== "" && <LazyLoadImage className="w-36 mb-5 shadow-lg rounded-lg" src={`${APPRO_IMAGE}${bookCover}`} />}
                    <Row lastChild="to-right">
                        <div className={`${bookCover !== "" ? "ml-3" : ""}`}>
                            <H2 className="flex-grow mobile:order-2 mobile:w-full mb-3">{data.title}</H2>
                            {data.ean !== "" && (
                                <label>
                                    EAN: <strong>{data.ean}</strong>
                                </label>
                            )}
                        </div>
                        <Button
                            onClick={() => onClickFav()}
                            className={clsx(
                                "flex-shrink-0 mobile:flex-grow",
                                fav ? "bg-label-amaranth-14 text-label-amaranth-08" : "bg-label-amaranth-17 text-label-amaranth-13",
                            )}
                            icon={fav ? "data-favorite" : "data-favorite-off"}
                        ></Button>
                    </Row>
                </div>
            ) : (
                <H2>{data.title}</H2>
            )}
            <Detail className="text-adjust-tone-08">
                <span dangerouslySetInnerHTML={{ __html: data.description }} />
            </Detail>
        </Grid>
    );
};

export default QuizDetail;
