import { Button, Detail, Form, InputText, Textarea, Radio } from "@maggioli-design-system/react";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { IForm } from "../../interfaces/IForm";
import { ILoader } from "../../interfaces/ILoader";
import { IField, IPrivacy } from "../../interfaces/IPrivacy";
import { ISubmit } from "../../interfaces/ISubmit";
import { getForms, sendDataToSubscriptions } from "../../services/PrivacyService";
import { emailValidation, getCookie } from "../../utils/Functions";
import { EXPRESS_URL, emailParams } from "../../utils/Macros";
import Connection from "../../utils/Connection";

interface Props {
    handleLoader: ILoader;
}

const TOKEN: string =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwcml2YWN5Lm1hZ2dpb2xpY2xvdWQuaXQiLCJhdWQiOjEsImlhdCI6MTYzMjk5ODY3OCwibmJmIjoxNjMyOTk4Njc4LCJleHAiOjE5NDgzNTg2NzgsImRhdGEiOnsiaWQiOjc1LCJzaXRlX2lkIjoxMDN9fQ.fzjC_iJsoUdHD7dgKrE5CA_H5nhoA6DSi6a7ycBjq94";
const INIT_TEXT: string = "Invia";
const INIT_STAT: string = "";

export default function ContactForm({ handleLoader }: Props): ReactElement {
    const [form, setForm] = useState<IForm>(null);
    const [commerciale, setCommerciale] = useState<string>(null);
    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });

    useEffect(() => {
        handleLoader.setLoaderVisible(true);
        getForms(TOKEN, (result: any) => {
            if (result.form === undefined || result.form.length === 0) {
                return;
            }
            setForm(result.form.filter((elem: IForm) => elem.nome === "contatti").pop());
            handleLoader.setLoaderVisible(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value);
                break;
            default:
                break;
        }
    };

    const privacyFields = (): JSX.Element[] => {
        return form.privacy.map((elem: IPrivacy, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={{ __html: elem.description }} />
                    {elem.fields.map((item: IField, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (submit.status !== "") {
            return;
        }

        if (commerciale === null) {
            alert("Tutti i campi sono obbligatori.");
            return;
        }

        const resIP = await Connection.get(`${EXPRESS_URL}ip`);
        if (!resIP.ok) {
            alert("Errore nel recupero dell'IP");
            return;
        }
        const dataIP = await resIP.json();
        const formData = new FormData(e.target);
        const privacy = {};
        for (const [key, value] of formData.entries()) {
            privacy[key] = value;
        }
        privacy["token"] = TOKEN;
        privacy["ip"] = dataIP.ip;

        if (!emailValidation(privacy["email"])) {
            alert("Email non valida.");
            return;
        }

        const payload = {
            to: "clienti.editore@maggioli.it", // email amministrazione (DA CAMBIARE IN PROD)
            from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Simulatore QuizConcorsi", //nome sito
            replyTo: formData.get("email"), //email inserita dall'utente
            subject: "Richiesta Assistenza Simulatore QuizConcorsi Maggioli",
            message: `<h1>Richiesta di assistenza da parte di ${formData.get("nome")} ${formData.get("cognome")}</h1>
                        <p>Messaggio:<br> ${formData.get("messaggio")} </p> <br> Email inviata da: ${formData.get("email")}`,
            responseParams: {
                to: formData.get("email"), //email inserita dall'utente
                message: "Grazie per averci contattato. A breve riceverai una risposta da parte dell'amministrazione di Simulatore QuizConcorsi.",
            },
            categories: ["no-reply-sitiweb@maggiolicloud.it", "simulatorequizconcorsi", "contatti"],
            privacy: privacy,
            params: emailParams,
        };

        handleLoader.setLoaderVisible(true);
        sendDataToSubscriptions(payload, (result: any) => {
            handleLoader.setLoaderVisible(false);
            if (result.response_status) {
                setSubmit({ text: "Richiesta inviata con successo!", status: "success" });
                window.setTimeout(() => window.location.reload(), 2000);
            }
        });
    };

    return (
        <div>
            {form !== null && (
                <Form className="view-limit" columns="1" onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)}>
                    {form.fields
                        .filter((elem) => elem.type !== "hidden" && elem.input !== "textarea")
                        .map((elem, index) => {
                            const loggedUser = JSON.parse(getCookie("loggedUser"));
                            const value = loggedUser !== null && elem.name === "email" ? loggedUser.email : "";
                            return <InputText value={value} key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />;
                        })}

                    {form.fields
                        .filter((elem) => elem.input === "textarea")
                        .map((elem, index) => (
                            <Textarea key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                        ))}
                    {form.fields
                        .filter((elem) => elem.type === "hidden")
                        .map((elem, index) => (
                            <input key={index} name={elem.name} value={elem.options.value} type="hidden" />
                        ))}

                    <input name="trattamento" value="1" type="hidden" />
                    <input name="servizio" value={form.servizio} type="hidden" />

                    {/* Consenso informato */}
                    <Detail htmlTag={"div"}>
                        <strong>
                            <p className="privacy-consent" dangerouslySetInnerHTML={{ __html: form.consenso_informato }} />
                        </strong>
                    </Detail>
                    {privacyFields()}
                    <div>
                        <Button variant={submit.status} type="submit">
                            {submit.text}
                        </Button>
                    </div>
                </Form>
            )}
        </div>
    );
}
