import { ReactElement } from 'react'
import {
    Detail,
    Grid,
    H2,
} from '@maggioli-design-system/react'
import { ILoader } from '../../interfaces/ILoader';
import Registerform from '../../components/Registerform/Registerform';
interface Props {
    handleLoader: ILoader,
}

export default function RegisterPage({ handleLoader }: Props): ReactElement {

    return ( 
        <div className="bg-adjust-tone-19 py-12 px-4 mobile:pt-4">
            <Grid columns="3" className="gap-10 mobile:gap-4 view-limit grid-tmpl-home mobile:grid-cols-1" rows="fit-vertically">
                <Grid className="gap-0" rows="fit-vertically">
                    <H2 className="mb-4">Registrati al Simulatore QuizConcorsi</H2>
                    <Detail>
                        Crea il tuo profilo per accedere alla simulazione gratuita, visualizzare 
                        la tua valutazione e accedere al report completo.                    
                    </Detail>
                </Grid>
                <Grid className="bg-adjust-tone-17 desktop:mx-24 rounded-3xl">
                    <Registerform free={window.location.pathname.includes('free')} handleLoader={handleLoader}/>
                </Grid>
            </Grid>
        </div>
    )
}
