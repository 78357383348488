import React from 'react'
import {
    Card,
    ExternalLink,
    Icon,
    Row,
} from '@maggioli-design-system/react'
import { IQuestion } from '../../interfaces/IQuestion'

interface Props {
    question: IQuestion,
}

export default function AnswerDescription({ question }: Props) {

    const description = question.description !== '' && question.description !== null ?
                        <p dangerouslySetInnerHTML={{ __html: question.description}}/> : null;

    const video = question.metadata.data.link_video !== undefined && question.metadata.data.link_video !== '' ?
                        <b className='mt-3'><ExternalLink href={question.metadata.data.link_video}>Link spiegazione</ExternalLink></b> : null;

    if (description === null && video === null) {
        return <div></div>;
    }

    return (
        <Card className="bg-status-info-19 gap-2 mb-6">
            <Row>
                <Icon name="crud-save"/>
                <b>Descrizione soluzione</b>
            </Row>
            { description }

            { video }
        </Card>
    )
}