import { LabelDetail, Detail } from "@maggioli-design-system/react";
import clsx from "clsx";

interface IDescriptionTip {
    children?: React.ReactNode;
    className?: string;
    lineHeight?: string;
    typography?: string;
    value?: string;
    fullWidth?: boolean;
}

const DescriptionTip: React.FC<IDescriptionTip> = ({
    children,
    className,
    lineHeight,
    typography = "label",
    value,
    fullWidth = false,
}: IDescriptionTip): JSX.Element => (
    <div className={clsx("flex gap-2", className)}>
        {typography === "label" ? (
            <div className={`${fullWidth ? "w-full" : "w-44"} flex leading-0`}>
                <LabelDetail className={clsx(lineHeight, "flex-grow min-w-0 truncate mr-2")}>{children}</LabelDetail>
                <LabelDetail className={clsx(lineHeight, "flex-shrink-0")}>{value}</LabelDetail>
            </div>
        ) : (
            <div className={`${fullWidth ? "w-full" : "w-44"} flex leading-0`}>
                <Detail className={clsx(lineHeight, "flex-grow min-w-0 truncate mr-2")}>{children}</Detail>
                <Detail className={clsx(lineHeight, "flex-shrink-0")}>{value}</Detail>
            </div>
        )}
    </div>
);

export default DescriptionTip;
