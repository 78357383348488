import { Caption, Detail, Grid, Image, H5, HeaderLogo } from '@maggioli-design-system/react'
import { Link } from 'react-router-dom'
import './Footer.css'
import { TailSpin } from 'react-loader-spinner'
import { scrollTop } from '../../utils/Functions'
import LogoEditore from '@maggioli-design-system/identity/dist/gruppo-maggioli/logo-gruppo-maggioli-256w.png';
import Logo from '../../logo.png'

interface Props {
  loaderVisible: boolean,
}

const Footer = ({ loaderVisible }: Props) : JSX.Element => {

  const linkColor: string = "text-brand-maggioli-04";

  const loaderComponent = (): JSX.Element => <>
    <div className="bg-loader-quiz"/>
    <div className="loader-quiz">
      <TailSpin color="#0041B9"/>
    </div>
  </>

  return <footer className="py-12 px-8 border-t-2 border-adjust-tone-18">
    { loaderVisible && loaderComponent() }
    <Grid gutter="small" className="view-limit grid-cols-3 mobile:grid-cols-1">

      <div>
        <Grid gutter="xsmall">
          <HeaderLogo src={Logo}/>
          <Caption className="mt-4">
            <strong>
              Simulatore QuizConcorsi è un servizio del <br/>
              Gruppo Maggioli
            </strong>
          </Caption>
          <Caption>
            Via del Carpino, 8<br />
            47822 Santarcangelo di Romagna (RN) Italia
          </Caption>
        </Grid>
      </div>

      <div>
        <Grid gutter="xsmall">
          <H5>Servizio clienti</H5>
          <Caption>
              Disponibile dal Lunedì al Venerdì<br />
              Dalle 08:30 alle 17:30
          </Caption>
          <Caption>
              Tel. +39 0541 628200<br />
          </Caption>
          <Detail>
            <Link to={"/contact"} onClick={scrollTop} className={linkColor}>Contattaci</Link>
          </Detail>
        </Grid> 
      </div>

      <div>
        <Grid gutter="xsmall">
          <Detail>
            <a href="/informativa-sui-cookies">Cookie policy</a> 
          </Detail>
          <Detail>
            <a href='https://privacy.maggiolicloud.it/privacy/simulatorequizconcorsiit' target='_blank' rel='noreferrer'>Privacy policy</a>
          </Detail>
          <Image className="w-16" src={LogoEditore}/>
        </Grid>
      </div>

    </Grid>
  </footer>
}

export default Footer;
