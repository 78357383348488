import { BenchmarkBar, Bullet, Button, Card, Detail, Grid, H2, H5, Row, InputText } from "@maggioli-design-system/react";

import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkFreeSimulations } from "../../services/CustomerHistoryService";
import { getCookie } from "../../utils/Functions";
import { ThreeDots } from "react-loader-spinner";

interface Props {
    text: string;
    setText: Function;
    frequentWords: string[];
}

export const maxFreeSimulations: number = 5;

export default function QuickSearch({ text, setText, frequentWords }: Props): ReactElement {
    const history = useNavigate();
    const IS_LOGGED: boolean = getCookie("loggedUser") !== null;
    const [selectedWord, setSelectedWord] = useState<number>(null);
    const [randomKey, setRandomKey] = useState<number>(Math.random());
    const [freeDone, setFreeDone] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const justFree: boolean = window.location.pathname.includes("free");

    useEffect(() => {
        checkFree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectQuickWord = (word: string, index: number) => {
        if (selectedWord === index) {
            setText("");
            setSelectedWord(null);
            setRandomKey(Math.random());
        } else {
            setText(word);
            setSelectedWord(index);
            setRandomKey(Math.random());
        }
    };

    const checkFree = async () => {
        if (!IS_LOGGED) {
            return;
        }
        setLoading(true);
        const res = await checkFreeSimulations(JSON.parse(getCookie("loggedUser")).id);
        setLoading(false);
        const data = await res.json();
        if (data.status) {
            setFreeDone(data.data);
        }
    };

    const availableTests: number = maxFreeSimulations - freeDone < 0 ? 0 : maxFreeSimulations - freeDone;

    return (
        <Grid className="gap-3 mobile:hidden" rows="fit-vertically">
            <div className="mb-1">
                <H2>{!justFree ? "Seleziona una delle prove per iniziare una simulazione" : "Inizia subito una simulazione gratuita"}</H2>

                <div>
                    <Card className={`rounded-xxl my-3 p-5 border-brand-maggioli-04 border-2 gap-1`}>
                        <H5>Scopri le prove gratuite</H5>
                        {justFree ? (
                            <Detail>
                                Il simulatore quiz concorsi è molto semplice e intuitivo: ti bastano <b>pochi click</b> per iniziare la tua
                                simulazione. <br />
                                Al termine della simulazione potrai visualizzare la tua valutazione e accedere al report completo. <br />
                                Per approfondire ulteriormente, potrai utilizzare i nostri manuali che comprendono l’accesso all’area riservata con il
                                software completo. <br />
                                Nota: sono disponibili <b>{maxFreeSimulations} simulazioni gratuite</b> per provare le funzionalità del sistema.
                            </Detail>
                        ) : (
                            <Detail>Registrati per provare le simulazioni gratuite</Detail>
                        )}
                        <Row className="mt-4" lastChild="to-right">
                            { !justFree &&
                                <Button className="justify-content-end" onClick={() => history("/free")}>
                                    Scopri le prove gratuite
                                </Button>
                            }
                            { justFree && !IS_LOGGED &&  (
                                <Button className="justify-content-end" onClick={() => history("/register/free")}>
                                    Registrati
                                </Button>
                            ) }
                            { justFree && <Button onClick={() => history("/")}>Torna alla home</Button>}
                        </Row>
                    </Card>
                </div>
            </div>

            {justFree && IS_LOGGED && !loading && (
                <BenchmarkBar progress={(availableTests / maxFreeSimulations) * 100} progressText={`${availableTests} / ${maxFreeSimulations}`}>
                    Simulazioni ancora disponibili
                </BenchmarkBar>
            )}
            {loading && justFree && <ThreeDots color="#0041B9" />}

            <Grid className="gap-2 sticky top-28">
                <InputText
                    key={randomKey}
                    value={text}
                    className="overflow-visible"
                    icon="data-search"
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Cerca una prova..."
                />

                {frequentWords.length > 0 && <Detail>Ultime ricerche</Detail>}
                <div className="flex flex-wrap gap-2">
                    {frequentWords.map((e, i) => {
                        const bgColor: string = i !== selectedWord ? "bg-adjust-tone-17" : "bg-status-warning-17";
                        return (
                            <div key={i} onClick={() => selectQuickWord(e, i)}>
                                <Bullet size="xlarge" className={`cursor-pointer ${bgColor}`}>
                                    {e}
                                </Bullet>
                            </div>
                        );
                    })}
                </div>
            </Grid>
        </Grid>
    );
}
