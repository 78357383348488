import { Image, InputText, Button, Form, InputPassword, Row } from "@maggioli-design-system/react";

import React, { ChangeEvent, ReactElement, useState } from "react";
import { ISubmit } from "../../interfaces/ISubmit";
import { login } from "../../services/ApproService";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../logo.png";
import { saveLogin } from "../../utils/Functions";

export default function LoginForm(): ReactElement {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ status: "", text: "Accedi" });

    const history = useNavigate();

    const onClickLogin = async () => {
        if (email === "" || password === "") {
            setSubmit({ text: "Inserire email e password", status: "error" });
            window.setTimeout(() => setSubmit({ status: "", text: "Accedi" }), 1500);
            return;
        }

        const res = await login(email, password);
        if (res.status) {
            saveLogin(res);
            setSubmit({ text: "Accesso effettuato!", status: "success" });
            window.setTimeout(() => window.location.reload(), 1300);
        } else {
            setSubmit({ text: res.message, status: "error" });
            window.setTimeout(() => setSubmit({ status: "", text: "Accedi" }), 1300);
        }
    };

    return (
        <Form
            className="bg-adjust-tone-18 p-8 rounded-3xl shadow-lg hover:shadow-xl"
            onSubmit={(e: ChangeEvent<HTMLFormElement>) => {
                e.preventDefault();
                onClickLogin();
            }}
        >
            <Image src={Logo} className="w-56 m-auto" />

            <InputText
                placeholder="Inserisci l'e-mail..."
                icon="document-email"
                value={email}
                onChange={(e: ChangeEvent<HTMLFormElement>) => setEmail(e.target.value)}
            />

            <InputPassword
                onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword(e.target.value)}
                value={password}
                placeholder="Inserisci la password..."
            />

            <Button horizontalPadding="large" variant={submit.status} type="submit">
                {submit.text}
            </Button>
            <Row lastChild="to-right">
                <Link to="/recover">
                    <Button variant="secondary-outline">Recupera password</Button>
                </Link>

                <Button variant="secondary" onClick={() => history("/register")}>
                    Registrati
                </Button>
            </Row>
        </Form>
    );
}
