import {
    Hr,
    Grid,
} from '@maggioli-design-system/react'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Answer from '../../components/Answer/Answer';
import AnswerDescription from '../../components/AnswerDescription/AnswerDescription';
import QuizHeader from '../../components/QuizHeader/QuizHeader';
import { IAnswer } from '../../interfaces/IAnswer';
import { ILoader } from '../../interfaces/ILoader';
import { IQuestion } from '../../interfaces/IQuestion';
import { getQuestionByID } from '../../services/QuestionService';
import { ALPHABET, IMAGE_EXPRESS_URL } from '../../utils/Macros';

interface Props {
	handleLoader: ILoader
}

const QuestionPreview = ({ handleLoader }: Props) : JSX.Element  => {

    const regex = /<img.*?src="(.*?)"/;
    const isOldImage = (title: string) => title.includes('/quiz/images/');
    const { id } = useParams<{ id: string }>(); 

    const [question, setQuestion] = useState<IQuestion>(null);

    useEffect(() => {
        loadQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadQuestion = async () => {
        handleLoader.setLoaderVisible(true);
        const res = await getQuestionByID(id);
        handleLoader.setLoaderVisible(false);
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        setQuestion(data)
    }

    if (question === null) {
        return <div className="bg-adjust-tone py-12 mobile:pt-4 view-limit desktop-max:p-4">
                <Hr className="bg-adjust-tone-18 my-4"/>
        </div>
    }

    const imageSrc = (e: IAnswer) => {
        if (isOldImage(e.title)) {
            const path: string = regex.exec(e.title)[1].replace('quiz', 'uploads');
            return `${IMAGE_EXPRESS_URL}url=${path}`;
        }
        return e.metadata.data !== undefined && e.metadata.data.url !== undefined ? `${IMAGE_EXPRESS_URL}url=${e.metadata.data.url}` : '';
    }

    const answerText = (e: IAnswer) => {
        if (isOldImage(e.title)) {
            return e.title.substring(0, e.title.indexOf('<br/>'));
        }
        return e.title;
    }

    const answers = () => {
        return <Grid className="pb-8 gap-6 grid grid-cols-2 mobile:grid-cols-1">
            { question.answers.map((e, i) => {

                const answerImage: string = imageSrc(e);
                const status = 'not-user-answered';

                return <div className="cursor-pointer" key={i} onClick={() => {}}>
                    <Answer answered={false} status={status} letter={ALPHABET[i]} src={answerImage}>
                        { answerText(e) }
                    </Answer>
                </div>
            }) }

        </Grid>
    }

    const questionsAndAnswers = () => {
        return <div>
            <QuizHeader timeLeftInSeconds={100} noStop={true} endModal={false} currentQuestion={question}/>
            
            { answers() }
            <AnswerDescription question={question}/> 
        </div>
    }

    return <div className="bg-adjust-tone py-12 mobile:pt-4 view-limit desktop-max:p-4">
        { questionsAndAnswers() }    
    </div>
}

export default QuestionPreview;
