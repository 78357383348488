import {
    Row,
} from '@maggioli-design-system/react'

import React, { ReactElement } from 'react'

interface Props {
    label: string,
    name: string,
    value: number,
    onChange: Function,
    max?: number,
    min?: number,
    decimalStep?: boolean,
}

export default function InputNumber({ label, name, value, onChange, min, max, decimalStep = false }: Props): ReactElement {

    const maxIsPresent: boolean = max !== undefined;
    const minIsPresent: boolean = min !== undefined;

    return (
        <label htmlFor="" className="input input--fill">
            <Row lastChild={maxIsPresent ? 'to-right' : ''}> 
                <div className="input__label text-secondary text-secondary--label-paragraph w-9/12">{label}</div>
                { maxIsPresent && <div className="input__label text-secondary text-secondary--label-paragraph">{value}/{max}</div> }
            </Row>
            <input value={value} type="number" onChange={e => onChange(e)} min={minIsPresent ? min : ''} name={name} step={decimalStep ? '.05' : '1'}
                    max={maxIsPresent && max > 0 ? max : ''} className="input__field text-secondary text-secondary--detail"/>
        </label>
    )
}
