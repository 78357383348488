import React, { ReactElement, useState } from 'react'
import { 
    Banner,
    Detail,
    Button,
    Modal,
    Grid,
    H3,
    InputText,
  } from '@maggioli-design-system/react';
import { redeemCode } from '../../services/ApproService';

interface Props {
    visible: boolean,
    setVisible: Function,
}

export default function RedeemCodeModal({ visible, setVisible }: Props): ReactElement {

    const [code, setCode] = useState<string>('')
    const [message, setMessage] = useState<string>('');

    const onClickRedeemCode = async () => {
        const res = await redeemCode(code);
        if (!res.status) {
          setMessage(res.message);
          window.setTimeout(() => setMessage(''), 2000);
          return;
        }
        
        setMessage('Codice riscattato con successo!');
        window.setTimeout(() => window.location.reload(), 2000);
      }

    return (
        <Modal visible={visible} onCancel={() => setVisible(false)} position="center" footer={false} >
          <H3>Inserisci il codice d'accesso</H3> <br />
          <b>Nota:</b> se il codice è già stato riscattato su Approfondimenti non sarà necessario riscattarlo anche qui.
          <Grid className="mt-7" gutter="small">
            <InputText value={code} onChange={e => setCode(e.target.value)} placeholder="Inserisci il codice..."/>
            <div><Button onClick={() => onClickRedeemCode()}>Riscatta</Button></div>

            { message !== '' &&
            <Banner className="rounded-xl">
              <Detail>{message}</Detail>
            </Banner> }
          </Grid>
        </Modal>
    )
}
