import React, { ReactElement, useState } from "react";
import { Button, H3, H4, Hr, Grid, Card, Detail, Paragraph, Row, Icon, UList, UListItem } from "@maggioli-design-system/react";
import { Link } from "react-router-dom";
import ContactForm from "../../components/ContactForm/ContactForm";
import { ILoader } from "../../interfaces/ILoader";
import ConfigImage from "../../assets/images/standard.png";
import CustomImage from "../../assets/images/custom.png";
import PlayImage from "../../assets/images/play.png";
import { maxFreeSimulations } from "../../components/QuickSearch/QuickSearch";

interface Props {
    handleLoader: ILoader;
}

export default function Help({ handleLoader }: Props): ReactElement {
    const [context, setContext] = useState<number>(1);
    const [standardImage, setStandardIMage] = useState<boolean>(true);

    const stepCard = (icon: string, title: string, desc: string, nContext: number) => {
        const selected: string = nContext === context ? "bg-adjust-tone-16" : "";
        return (
            <div onClick={() => setContext(nContext)} className="cursor-pointer">
                <Card className={`color-brand-maggioli-05 hover:bg-adjust-tone-17 ${selected} `} padding="xsmall">
                    <Row>
                        <Icon size="xlarge" name={icon} />

                        <Grid gutter="none" columns="1">
                            <Paragraph className="color-adjust-tone-04">{title}</Paragraph>
                            <Detail className="color-adjust-tone-04">{desc}</Detail>
                        </Grid>
                    </Row>
                </Card>
            </div>
        );
    };

    const registerContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-2 gap-1">
            <H4>Registrazione</H4>
            Clicca il pulsante “Registrati” per creare il tuo account. Riceverai un’email di conferma nella quale troverai un link per
            l&#39;attivazione dell&#39;account su "Simulatore Quiz Concorsi". <br />
            In caso di mancato ricevimento dell'email controllare la casella di posta indesiderata. <br />
            <br />
            <div>
                <b>Nota:</b> le credenziali utilizzate per registrarsi sono le stesse per accedere anche ad Approfondimenti.
            </div>
            <Link to="/register" className="mt-5">
                <Button variant="primary-outline">Registrati</Button>
            </Link>
        </Card>
    );

    const loginContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-2 gap-1">
            <H4>Come accedere</H4>
            Per accedere al Simulatore Quiz Concorsi è necessario essere registrati ad Approfondimenti ed utilizzare le relative credenziali. <br />
            Una volta effettuato l'accesso sarà possibile visionare tutte le prove che dispongono di simulazioni attive. <br /> <br />
            <div>
                <b>Nota:</b> è possibile eseguire le simulazioni solo delle prove delle quali è stato riscattato il codice, fatta eccezione per le
                simulazioni gratuite. Per questa categoria particolare si hanno a disposizione {maxFreeSimulations} prove da effettuare liberamente.{" "}
                <br />
            </div>
        </Card>
    );

    const redeemCodeContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-2 gap-1">
            <H4>Codice d'accesso</H4>
            Effettuato l'accesso al portale sarà possibile visionare tutte le prove che dispongono di simulazioni attive. <br />
            Per esercitarsi con le simulazioni è necessario riscattare il codice contenuto nei volumi dei concorsi. <br />
            <br />
            Cliccare sul pulsante "Inserisci il codice d'accesso" che apparirà nella barra in alto dopo il login. <br />
            <br />
            <div>
                <b>Nota:</b> se il codice è già stato riscattato su Approfondimenti non sarà necessario replicare questo passaggio.
            </div>
        </Card>
    );

    const simulationsConfigContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-3 gap-1">
            <Grid columns="2">
                <div>
                    <H4>Configurazione simulazione</H4>
                    Accedendo ad una prova saranno visualizzate tutte le simulazioni attive. <br />
                    Per eseguire una nuova simulazione premere su "nuova". A questo punto è possibile scegliere se iniziare la simulazione con le
                    impostazioni già inserite di base oppure personalizzarla. <br />
                    <br />
                    Di seguito sono mostrate le schermate di inizio di una simulazione standard e di una simulazione personalizzata. <br />
                    In quest'ultima sono mostrati i parametri che è possibile modificare a proprio piacere come:
                    <UList className="gap-0">
                        <UListItem>tempo</UListItem>
                        <UListItem>punteggio</UListItem>
                        <UListItem>numero di domande per materia</UListItem>
                        <UListItem>scegliere se mostrare le risposte corrette durante la prova</UListItem>
                        <UListItem>scegliere se è possibile lasciare le domande senza risposta</UListItem>
                    </UList>
                    <br />
                    Infine, attivando l'opzione "Simulazione su singola materia", si potrà scegliere una materia su cui esercitarsi nella nuova
                    simulazione.
                </div>
                <div>
                    <img
                        className="bg-adjust-tone-16 p-2 rounded-xl"
                        src={standardImage ? ConfigImage : CustomImage}
                        alt="immagine descrittiva della simulazione"
                    />
                    <Button className="mt-2 mx-auto" variant="primary-outline" onClick={() => setStandardIMage(!standardImage)}>
                        {standardImage ? "Standard" : "Personalizzata"}
                    </Button>
                </div>
            </Grid>
        </Card>
    );

    const simulationsPlayContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-3 gap-1">
            <H4>Svolgimento simulazione</H4>
            <div>
                La simulazione viene fatta partire cliccando su <b>"Inizia simulazione"</b>. Una volta fatto ciò, viene mostrata la schermata del quiz
                vero e proprio. <br />
                In alto si trova la barra che contiene le informazioni della prova in corso come è possibile vedere nell'immagine sottostante. <br />
                Il tasto <b>"Riepilogo domande"</b> consente di visualizzare tutte le domande della simulazione, evidenziando quelle già completate, e
                di terminare la simulazione a proprio piacere (se le impostazioni lo consentono). <br />
            </div>
            <div>
                Per <b>passare da una domanda all'altra</b> si possono utilizzare i <b>pulsanti a schermo</b>, oppure, se si sta utilizzando un
                computer, le <b>frecce direzionali</b>. In alternativa sono disponibili i <b>tasti "a" e "d"</b> rispettivamente per andare
                <b> indietro e avanti</b> tra le domande.
            </div>
            <div>
                <b>Nota: </b>le prove lasciate in sospeso verranno tenute in memoria automaticamente in modo da poterle riprendere in un secondo
                momento cliccando sul tasto <b>"Riprendi"</b> prima di iniziare una nuova simulazione. Le{" "}
                <b>simulazioni in sospeso possono essere riprese e completate entro 1 mese dall'avvio</b> dopodiché verranno rimosse in automatico.
            </div>
            <img className="bg-adjust-tone-16 p-2 rounded-xl w-3/4 mt-2" src={PlayImage} alt="immagine descrittiva della simulazione" />
        </Card>
    );

    return (
        <div className="padding-normal bg-adjust-tone-19 py-10">
            <H3 className="text-center">Guida</H3>

            <Grid className="wide:mx-32 wide:px-6 my-10 desktop:grid-cols-4 desktop-max:px-5" gutter="" rows="fit-vertically">
                <Grid className="desktop:col-span-1" rows="fit-vertically">
                    {stepCard("crud-create", "Registrati", "", 2)}
                    {stepCard("security-fingerprint", "Accesso", "", 1)}
                    {stepCard("code-isbn", "Codice d'accesso", "", 3)}
                    {stepCard("file-executable", "Configurazione simulazioni", "", 4)}
                    {stepCard("file-executable", "Svolgimento simulazioni", "", 5)}
                </Grid>

                {context === 1 && loginContent()}
                {context === 2 && registerContent()}
                {context === 3 && redeemCodeContent()}
                {context === 4 && simulationsConfigContent()}
                {context === 5 && simulationsPlayContent()}
            </Grid>
            <Hr />

            <H4 className="text-center mt-16">Non hai trovato quello che cerchi?</H4>
            <div className="text-center">Contattaci</div>
            <div className="w-2/4 tablet-max:w-full mx-auto desktop:px-32 tablet-max:px-10">
                <ContactForm handleLoader={handleLoader} />
            </div>
        </div>
    );
}
