import {
  Icon,
  H2,
  H4,
  LabelParagraph,
} from '@maggioli-design-system/react'

import verdictVariant from './variant.json'
import { verdict, verdictText } from '../../functions/verdict'
import clsx from 'clsx'

interface ISimulationBadge {
  pointsMade?: number,
  pointsNeeded?: number,
  pointsTotal?: number,
}

const SimulationBadge: React.FC <ISimulationBadge> = ({
  pointsMade = 180,
  pointsNeeded = 180,
  pointsTotal = 200,
}: ISimulationBadge) : JSX.Element => {

  const result = verdict(pointsMade, pointsNeeded)
  const { title, text } = verdictText(pointsMade, pointsNeeded)

  return <div className={clsx(verdictVariant[result].badge , 'flex flex-col overflow-hidden rounded-2xl text-adjust-tone')}>
    <div className="flex gap-6 items-center px-10 py-5">
      <div className={clsx(verdictVariant[result].iconColor, 'flex items-center justify-center p-2 rounded-lg')}>
        <Icon size="xlarge" className="flex-shrink-0" name={verdictVariant[result].icon}/>
      </div>
      <div className="flex flex-col flex-grow">
        <H2>{ title }</H2>
        <LabelParagraph>{text}</LabelParagraph>
      </div>
    </div>
    <div className={clsx(verdictVariant[result].points, 'px-10 py-5 flex gap-4')}>
      <H4>Punteggio</H4>
      <H4 className="ml-auto">{ pointsMade } / { pointsTotal }</H4>
    </div>
  </div>
}

export default SimulationBadge
