import { H2, Detail } from "@maggioli-design-system/react";

const VolumeRelated: React.FC = (): JSX.Element => {
    const isFree: boolean = window.location.pathname.includes("free");

    return (
        <div className="bg-adjust-tone-19 py-1 px-4 mobile:pt-4">
            {isFree ? (
                <div className=" text-center sticky mt-10 view-limit">
                    <H2>Simulazioni gratuite</H2>
                    <Detail>
                        Il simulatore online Maggioli Editore ti permetterà di esercitarti in modo semplice e gratuito con le batterie di quiz dei
                        concorsi pubblici (enti pubblici, enti locali, forze armate, concorsi sanità, concorsi scuola, test di ammissione
                        all’Università) e di allenarti sulle <b>materie più richieste</b> nei concorsi pubblici.
                    </Detail>
                </div>
            ) : (
                <div className=" text-center sticky mt-10">
                    <H2>Prove e test associati ai tuoi volumi Maggioli</H2>
                    <Detail>Di seguito troverai tutti i volumi che hai associato al tuo account che hanno prove o test associati ad esso.</Detail>
                </div>
            )}
        </div>
    );
};

export default VolumeRelated;
