import {
    Button,
    Grid,
    Modal,
    Select,
} from '@maggioli-design-system/react'
import { Fragment, useState } from 'react'
import SimulationResultPill from '../SimulationResultPill'
import { getPointsDone, scorePerTheme } from '../../utils/Functions'
import { IHistory } from '../../interfaces/ICustomerHistory'

interface ISimulationHistoryItem {
    simulations: IHistory[],
    testID: string,
    deleteUpdate: Function,
}

const SimulationHistoryItem: React.FC<ISimulationHistoryItem> = ({
    simulations = [],
    testID,
    deleteUpdate = () => {}
}: ISimulationHistoryItem): JSX.Element => {

    const filterOptions: string[] = ['Tutte', 'Superate', 'Non superate'];

    const [opened, setModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('Tutte');

    if (simulations.length === 0) {
        return <div></div>;
    }

    simulations.sort((a, b) => b.id - a.id);
    const data = JSON.parse(simulations[0].json_data);
    const config = data.simulation.settings.data;
    const pointsTotal: number = scorePerTheme(config) ? config.themes.reduce((a, b) => a + (b.number * b.right), 0) : config.total_questions * config.right;
    const pointsMade: number = getPointsDone(data);

    const getSimulations = () => {
        if (filter === 'Tutte') {
            return simulations;
        }
        if (filter === 'Superate') {
            return simulations.filter(s => {
                const jsonData = JSON.parse(s.json_data);
                if (jsonData.simulation.settings.data.min_score <= getPointsDone(jsonData)) {
                    return s;
                }
                return null;
            });
        }
        if (filter === 'Non superate') {
            return simulations.filter(s => {
                const jsonData = JSON.parse(s.json_data);
                if (jsonData.simulation.settings.data.min_score > getPointsDone(jsonData)) {
                    return s;
                }
                return null;
            });
        }
    }

    const title: string = data.simulation.title.slice(0, 30) + (`${data.simulation.title.length > 30 ? '...' : '' }`);

    return <Fragment>
        <div className="flex gap-2 flex-grow">
            <SimulationResultPill pointsNeeded={config.min_score} pointsTotal={pointsTotal} pointsMade={pointsMade} deleteUpdate={deleteUpdate}
                    simulationDate={simulations[0].updated_at} simulationTitle={title} testID={testID} id={simulations[0].id.toString()}/>
            <Button variant="secondary-outline" onClick={() => setModal(true)}>Vedi tutte</Button>
        </div>
        <Modal maxWidth="600" visible={opened} footer={false} onConfirm={() => setModal(false)} onCancel={() => setModal(false)}>
            <Grid className="gap-4">
                <Select icon="data-filtered" className="mb-4" data={filterOptions} value={filter} onChange={e => setFilter(e.target.value)}/>
                <Grid className="gap-2">
                    { getSimulations().map((s, i) => {
                        const jsonData = JSON.parse(s.json_data);
                        const total: number = scorePerTheme(jsonData.simulation.settings.data) ? jsonData.simulation.settings.data.themes.reduce((a, b) => a + (b.number * b.right), 0)
                                                : jsonData.simulation.settings.data.total_questions * jsonData.simulation.settings.data.right;
                        const done: number = getPointsDone(jsonData);
                        return <SimulationResultPill key={i} pointsTotal={total} pointsMade={done} simulationDate={s.created_at} testID={testID}
                                    pointsNeeded={jsonData.simulation.settings.data.min_score} simulationTitle={jsonData.simulation.title} deleteUpdate={deleteUpdate}
                                    saveIndexSimulation={() => localStorage.setItem('simulationID', s.id.toString())} id={s.id.toString()}/>
                    })}
                </Grid>
            </Grid>
        </Modal>
    </Fragment>
}

export default SimulationHistoryItem
