import './Home.css'
import { ILoader } from '../../interfaces/ILoader';
import QuizList from '../../components/QuizList'
import { ITest } from '../../interfaces/ITest';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IFavManager } from '../../interfaces/IFavManager';
import { loginByPasscode } from '../../services/ApproService';
import { saveLogin } from '../../utils/Functions';

interface Props {
	handleLoader: ILoader
}

const Home = ({ handleLoader }: Props) : JSX.Element => {

	const { passCode } = useParams<{ passCode: string }>(); 
	const savedFav: ITest[] = JSON.parse(localStorage.getItem('favourites'));
	const [fav, setFav] = useState<ITest[]>(savedFav !== null ? savedFav : []);

	useEffect(() => {
		passCode !== undefined && loginByCode();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
  
	const favManager: IFavManager = {
	  fav: fav,
	  setFav: e => setFav(e)
	}

	const loginByCode = async () => {
		handleLoader.setLoaderVisible(true);
		const res = await loginByPasscode(passCode);
		if (res.status) {
			saveLogin(res);
			const path: string[] = window.location.href.split('/');
			window.setTimeout(() => window.location.replace(path.slice(0, path.length - 1).join('/')), 0);
		} else {
			handleLoader.setLoaderVisible(false);
		}
	}

	return <QuizList favManager={favManager} handleLoader={handleLoader}/>
}

export default Home;
