import {
  H6,
  Icon,
} from '@maggioli-design-system/react'
import clsx from 'clsx'

interface ITitle {
  className?: string,
  icon?: string,
  children?: React.ReactChild,
}

const Title: React.FC <ITitle> = ({
  className,
  children,
  icon = 'database',
}:ITitle): JSX.Element => {
  return <div className={clsx('flex gap-2', className)}>
    <Icon name={icon}/>
    <H6>{ children }</H6>
  </div>
}

export default Title
