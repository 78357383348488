import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "typeface-karla";
import "typeface-roboto";
import "@maggioli-design-system/styles/dist/css/typography.css";
import "@maggioli-design-system/styles/dist/css/components.css";
import "@maggioli-design-system/icons/dist/mgg-icons.css";
import "@maggioli-design-system/design-tokens/dist/css/vars-rgb-channels.css";
import "./styles/tailwind.generated.css";

const Render = () => {
    return <App />;
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Render />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
