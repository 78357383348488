import {
    Banner,
    Detail,
    Grid,
    Image,
    H3,
} from '@maggioli-design-system/react'
import { IQuestion } from '../../interfaces/IQuestion'

import { IMAGE_EXPRESS_URL } from '../../utils/Macros'

interface IQuizHeader {
    currentQuestion: IQuestion,
    timeLeftInSeconds: number,
    noStop: boolean,
    endModal: boolean,
}

const QuizHeader: React.FC<IQuizHeader> = ({
    currentQuestion,
    timeLeftInSeconds,
    noStop,
    endModal,
}: IQuizHeader): JSX.Element => {

    const isOldImage: boolean = currentQuestion.title.includes('/quiz/images/');
    const regex = /<img.*?src='(.*?)'/;
    const regexDouble = /<img.*?src="(.*?)"/;
    
    const oldImageSrc = () => {
        if (isOldImage) {
            const checkDouble = regexDouble.exec(currentQuestion.title);
            const checkSingle = regex.exec(currentQuestion.title);
            const finalPath: string = (checkDouble !== null ? checkDouble : checkSingle)[1].replace('quiz', 'uploads');
            return finalPath;
        }
        return '';
    }

    const questionText = () => {
        return currentQuestion.title.replace(/<img[^>]*>/g, '');
    }

    return <Grid className="text-center mobile:grid-cols-1 mobile:gap-0 gap-6 pb-3">

        { timeLeftInSeconds >= 0 && timeLeftInSeconds <= 60 && !endModal && 
        <Banner className="rounded-2xl" status="warning">
            <Detail>
                { !noStop ? 
                `Attenzione, mancano ${timeLeftInSeconds} secondi allo scadere del tempo!`
                : 
                `Mancano ${timeLeftInSeconds} allo scadere del tempo regolare. Dopodiché la simulazione continuerà.`}
            </Detail>
        </Banner> }

        { timeLeftInSeconds < 0 && !endModal && 
        <Banner className="rounded-2xl" status="warning">
            <Detail>
                Tempo in eccesso: { Math.abs(timeLeftInSeconds) } secondi.
            </Detail>
        </Banner> }

        <H3 className="p-6 mobile:text-center mobile:pt-0">
            <p dangerouslySetInnerHTML={{ __html: questionText() }}/>
        </H3>

        { isOldImage &&
        <Grid className="-mt-7 mx-auto">
            <Image className="rounded-3xl border-8 border-adjust-tone-19" src={`${IMAGE_EXPRESS_URL}url=${oldImageSrc()}`}/>
        </Grid> }

        {  currentQuestion.metadata !== undefined && currentQuestion.metadata.data.url && 
        <Grid className="-mt-7 mx-auto">
            <Image className="rounded-3xl border-8 border-adjust-tone-19" 
                src={`${IMAGE_EXPRESS_URL}url=${currentQuestion.metadata.data.url}`}/>
        </Grid> }
    </Grid>
}

export default QuizHeader
