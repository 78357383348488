export const difficulty = difficultyPercentage => {
  let status = 'Difficilissima'
  if (difficultyPercentage < 90) {
    status = 'Molto difficile'
  }
  if (difficultyPercentage < 80) {
    status = 'Difficile'
  }
  if (difficultyPercentage < 70) {
    status = 'Intermedia'
  }
  if (difficultyPercentage < 50) {
    status = 'Facile'
  }
  if (difficultyPercentage < 40) {
    status = 'Molto facile'
  }
  if (difficultyPercentage < 20) {
    status = 'Facilissima'
  }
  if (difficultyPercentage === 0) {
    status = 'Non definita'
  }
  return status
}
