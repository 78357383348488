import Connection from '../utils/Connection';
import { EXPRESS_URL } from '../utils/Macros';

export async function getQuestionsFromAPI(results: number) {
    return await Connection.get(`${EXPRESS_URL}questions?page=1&per_page=${results}&query=`)
}

export async function getQuestionByID(id: string) {
    return await Connection.get(`${EXPRESS_URL}questions/${id}`)
}

export async function getRandomQuestions(themeID: string, num: number = 1) {
    return await Connection.get(`${EXPRESS_URL}themes/random-quest/${themeID}/${num}`);
}